.admin-main-container {
  display: flex;
  .side-bar {
    width: fit-content;
    background: #ff5757;
    padding: 20px;
    ul {
      padding: 0;
      .active {
        color: #fac71a;
        border-bottom: 2px solid #fac71a;
      }
      li {
        font-family: Krabuler;
        color: #fff;
        list-style: none;
        font-size: 21px;
        &:hover {
          color: #fac71a;
        }
      }
    }
  }
}
