.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-block: 40px;
	width: 100%;
	.box {
		padding-block: 20px;
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;
		border-radius: 64px;
		background: linear-gradient(90deg, rgba(255, 87, 87, 1) 84%, rgba(255, 255, 255, 0) 100%);
		width: 90%;
		.form {
			display: flex;
			padding-left: 5%;
			flex-direction: column;
			gap: 20px;
			z-index: 1;
			width: 40%;
			min-width: 350px;
			h2{
				color: #fff;
				font-weight: 300;
				font-size: 3rem;
				span {
					font-size: 4rem;
					font-weight: 500;
				}
			}
			.wrapButton {
				display: flex;
				justify-content: flex-end;
				.spinner-contain{
					width: 30%;
					min-width: 100px;
					height: 80px;
				}
				button {
					border: none;
					border-radius: 100px;
					background: #fff;
					box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
					color: #160041;
					font-size: 2rem;
					padding-inline: 40px;
				}
			}
			.wrapInputs {
				display: flex;
				flex-direction: column;
				gap: 20px;
				.input-container{
					display: flex;
					flex-direction: column;
					gap: 10px;
					position: relative;
					input,
					textarea {
						padding-block: 8px;
						padding-inline: 20px;
						display: flex;
						align-items: center;
						border-radius: 32px;
						background: #fff;
						color: #606060;
						font-size: 1.8rem;
						font-weight: 500;
						border: none;
						outline: none;
						width: 100%;
					}
					textarea {
						height: 180px;
						overflow: hidden;
					}
					.error{
						font-size: 1.4rem;
						color: #f3e95d;
						position: absolute;
						bottom: -20px;
						left: 50%;
						transform: translateX(-50%);
						white-space: nowrap;
						font-weight: 600;
					}
				}
			}
		}
		.img1 {
			position: absolute;
			top: 10%;
			right: 45%;
			z-index: -19;
			opacity: 0.9;
			background-size: cover;
			background-repeat: no-repeat;
			transform: scale(2);
			mix-blend-mode: multiply;
		}
		.cecy-img {
			width: 80%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0px;
			right: 0px;
		}
		.cecy-img-resp{
			display: none;
			width: 100%;
			height: 30%;
			object-fit: cover;
			position: absolute;
			top: unset;
			right: unset;
			left: 0px;
			bottom: -5px;
		}
	}
	@media (max-width: 600px){
		.box{
			height: 80vh;
			align-items: unset;
			border-radius: 40px;
			background-color:rgba(255, 87, 87, 1)  ;
			.form{
				width: 100%;
				padding-inline: 8%;
				min-width: unset;
				h2{
					text-align: center;
					font-size: 4rem;
				}
				.wrapButton{
					button{
						font-size: 1.6rem;
						padding-inline: 30px;
						padding-block: 12px;
					}
				}
			}
			.cecy-img{
				display: none;
			}
			.cecy-img-resp{
				display: unset;
			}
		}
	}
	@media(max-width: 450px){
		.box{
			.form{
				h2{
					
					font-size: 3rem;
				}
			}
		}
	}
}