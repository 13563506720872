.btnRegalo{
   position: absolute;
   top: 100px;
   left: 0px;
   border-radius: 0px 24px 24px 0px;

   width: 100%;
   max-width: 360px;
   background: linear-gradient(180deg, #FF5757 0%, #EB00FF 100%);
   cursor: pointer;
   padding: 0px 7px;
   &:hover{
      opacity: 0.6;
   }
   p{
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      span{
         color: white;
         padding: 12px 7px;
         font-size: 26px;   
      }
      .regaloIcon{
         transition: all 300ms ease-in-out;
         color: white;
         font-size: 25px;
         margin: 0;

         animation-name: shake;
         animation-iteration-count: infinite;
         animation-duration: 6s;
      }
   }
      
   @media( max-width: 1300px ){
         max-width: 290px;
         p {
            span{
               font-size: 19px;
            }
            .regaloIcon{
               font-size: 20px;   
            }
         }
   }
   @media ( max-width: 900px ) {
         top: 105px;
         left: 0px;
         padding: 0px 5px;
         max-width: 230px;
         p{
            span{
               padding: 8px 10px;
               font-size: 15px;   
            }
   
            .regaloIcon{
               font-size: 16px;
               padding: 0px;
            }
         }
   }
}


@keyframes shake {
   
   20% {
      transform: rotate( 0deg ) ;

   }
   23%{
      transform: rotate( 45deg );
   }
   26%{
      transform: rotate( -45deg );
   }
   35%{ 
      transform: rotate( 0deg );
   }

   100% {transform: rotate( 0deg ) ; }
}