.admin-powercoach-forum-card {
	display: flex;
	padding: 40px;
	gap: 15px;
	border-radius: 10px;
	box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
	align-items: center;
	margin-top: 20px;
	color: #ff5757;
	img {
		width: 200px;
		height: 100px;
		object-fit: cover;
	}
	a {
		cursor: pointer;
		margin-left: auto;
	}
}
