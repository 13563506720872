.lesson-link-card {
    text-decoration: none;
    color: black;
}
.lesson-card {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    border: none;
    box-shadow: rgba(149, 157, 165, 0.359) 0px 8px 24px;
    overflow: hidden;
    cursor: pointer;

    .image-container {
        width: 100%;
        max-width: 300px;
        min-width: 250px;
        aspect-ratio: 295/166;
        animation: skeleton-loading 1s linear infinite alternate;
        overflow: hidden;

        img {
            width: 100%;
            aspect-ratio: 295/166;
        }
    }

    .lesson-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        padding-left: 40px;
        width: 100%;
        background-color: var(--power-coach-bg-color);

        .title {
            h2 {
                font-size: 2.8rem;
            }
        }

        .description {
            p {
                font-size: 2rem;
            }
        }
    }
}

@media (max-width: 650px) {
    .lesson-card {
        flex-wrap: wrap;

        .image-container {
            max-width: none;
        }

        .lesson-info-container {
            padding: 20px;
        }
    }
}

@keyframes skeleton-loading {
    0% {
        background-color: #d6d6d6;
    }
    100% {
        background-color: #f0f0f0;
    }
}
