.lecture-new-comment-main-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    * {
        margin: 0;
        padding: 0px;
        font-size: 1.6rem;
    }

    .new-comment-card {
        background-color: var(--power-coach-bg-color);
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 5px;

        .input-container {
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 220px;

            textarea {
                width: 100%;
                height: 100%;
                resize: none;
                border: none;
                background-color: transparent;
                padding: 10px;
            }
        }

        .image-container {
            max-width: 100px;
            aspect-ratio: 1/1;
            border-radius: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
            min-width: 50px;
            img {
                width: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .submit-btn-container  {
        width: 100%;
        text-align: end;
        button {
            background-color: var(--power-coach-color);
            border-radius: 5px;
            border: none;
            color: #ffffff;
            padding: 5px 10px;
            margin-left: auto;
        }
    }
}