.section-four{
    display: flex;
    flex-direction: column;
    .main-contain{
        display: flex;
        background-color: #FEF8E3;
        padding-top: 120px;
        position: relative;
        justify-content: center;
        .title{
            position: absolute;
            background: linear-gradient(to bottom, #FCDC71 35%, #fff 100%);
            padding-inline: 25px;
            padding-block: 5px;
            padding-bottom: 30px;
            border-radius: 30px 30px 0px 0px;
            color: #fff;
            top: 60px;
            left: 12%;
            font-weight: 400;
            z-index: 1;
        }
        .testimony-contain{
            display: flex;
            gap: 30px;
            padding-block: 20px;
            padding-inline: 20px;
            border-top: 1px solid #FCDC71;
            background-color: #FFFDF5;
            border-radius: 40px;
            width: 100%;
            margin-inline: 6%;
            overflow: hidden;
            position: relative;
            z-index: 3;
            .bottom-contain {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding-inline: 50px;
                padding-block: 30px;
                border-radius: 50px;
                background-color: white;
                min-width: 400px;
                width: 450px;
                box-shadow: -10px 14px 20px #e5e5e5;
                z-index: 2;
                order: 3;
                transition: .5s ease all;
                .about {
                    max-height: 100px;
                    overflow: auto;
                    line-height: 20px;
                    text-align: justify;
                    padding-right: 10px;
                    font-size: 14px;
                    &::-webkit-scrollbar {
                        width: 6px;
                    }
                }
                .row-data {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .name {
                        font-size: 2rem;
                        font-weight: 600;
                    }
                    svg{
                        width: 40px;
                        height: 35px;
                    }
                    .user {
                        min-width: 45px;
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
            }
            .scroll-1{
                border-top: 2px solid #160041;
                border-right: 2px solid #160041;
                border-left: 8px solid #160041;
                border-bottom: 8px solid #160041;
                .about{
                    color: #160041;
                    &::-webkit-scrollbar-thumb {
                        background: #160041;
                        border-radius: 6px;
                    }
                }
                .row-data{
                    .name{
                        color: #160041;
                    }
                }

            }
            .scroll-2{
                border-top: 2px solid #935054;
                border-right: 2px solid #935054;
                border-left: 8px solid #935054;
                border-bottom: 8px solid #935054;
                .about{
                    color: #935054;
                    &::-webkit-scrollbar-thumb {
                        background: #935054;
                        border-radius: 6px;
                    }
                }
                .row-data{
                    .name{
                        color: #935054;
                    }
                }
            }
            .scroll-3{
                border-top: 2px solid #FF5757;
                border-right: 2px solid #FF5757;
                border-left: 8px solid #FF5757;
                border-bottom: 8px solid #FF5757;
                .about{
                    color: #FF5757;
                    &::-webkit-scrollbar-thumb {
                        background: #FF5757;
                        border-radius: 6px;
                    }
                }
                .row-data{
                    .name{
                        color: #FF5757;
                    }
                }
            }
        }
    }
    .button-contain{
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 10px;
        margin-top: 20px;
        .arrow-contain{
            background-color: #FAC71A;
            padding-inline: 25px;
            padding-block: 10px;
            border-radius: 100px;
            cursor: pointer;
            svg{
                color: #fff;
                font-size: 1.4rem;
            }
            &:hover{
                opacity: .67;
            }
        }
        .disable{
            background-color: #b9b7b7;
            cursor: not-allowed;
            &:hover{
                opacity: 1;
            }
        }
    }
    @media(max-width: 1023px){
        .main-contain{
            padding-bottom: 40px;
            .testimony-contain{
                border-radius: 40px 0px 0px 40px;
                margin-right: 0px;
            }
        }
    }
    @media(max-width: 600px){
        .main-contain{
            .testimony-contain{
                .bottom-contain{
                    min-width: 300px;
                    width: 400px;
                    padding-block: 20px;
                    padding-inline: 30px;
                    .about{
                        max-height: 150px;
                    }
                    .row-data{
                        svg{
                            width: 30px;
                            height: 26px;
                        }
                        .user{
                            width: 35px;
                            height: 35px;
                            min-width: 35px;
                        }
                    }
                }
            }
        }
    }
}