.section-three{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top: 20px;
    padding-block: 50px;
    .main-contain{
        display: flex;
        gap: 50px;
        padding-top: 65px;
        width: 100%;
        justify-content: space-around;
        padding-inline: 8%;
        .program-contain{
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;
            align-items: center;
            img{
                width: 100px;
            }
            .brown{
                color: #935054;
            }
            .yellow{
                color: #FFA800;
            }
            p{
                font-size: 1.4rem;
            }
        }
    }
    .background{
        width: 100%;
        position: absolute;
        height: 100%;
        object-fit: cover;
        top: 0px;
        left: 0px;
        z-index: -1;
    }
    @media(max-width: 850px){
        .main-contain{
            flex-direction: column;
            gap: 20px;
            .program-contain{
                padding: 30px;
                border-radius: 20px;
                background-color:#F6F6F680;
            }
        }
    }
    @media(max-width: 400px){
        .main-contain{
            .program-contain{
                padding: 15px;
            }
        }
    }
}