.header {
	display: inline-flex;
	padding-top: 32px;
	padding-bottom: 20px;
	justify-content: space-between;
	width: 85.46875%;
	gap: 10px;
	
	& *{
      transition: all 300ms ease-in-out;
   }
}

.sidebar {
	display: none;
}

.logo {
	width: 100%;
}

.wrapNavbarCenter {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 55%;
	margin: auto;
}

.navbarCenter {
	display: flex;
	justify-content: space-evenly;
	width: 100%;
	list-style: none;
	margin: auto;
	padding: 0;
}

$fs: 2.1rem;

.link {
	color: #160041;
	text-align: center;
	font-feature-settings: "clig" off, "liga" off;
	font-family: Inter;
	font-size: $fs;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	cursor: pointer;
	padding: 0;
	margin: 0;
	width: fit-content;
	position: relative;
	&.active {
		color: #FF5757;
		&::after{
			position: absolute;
			content: "";
			width:  9px;
			height: 9px;

			bottom: -11px;
			left: calc( 50% - 4.5px );
			
			border-radius: 50%;
			background-color: #FF5757;
		}
	}


}

.authWrap {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	list-style: none;
	gap: 24px;

	margin: 0;
	padding: 0;
}

.image {
	vertical-align: middle;
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.btn,
.btn:active {
	border-radius: 64px;
	background: #160041;
	border: none;
	outline: none;
	padding: 17px 36px 18px 36px;
	color: white;
	font-size: $fs;
	font-weight: 700;
	text-align: center;
	font-family: "Inter";
	width: fit-content;
}

.wrapNavbarCenter {
	display: block;
}

.botones {
	display: block;
}

.wrapSidebar {
	display: none;
}

.wrapEsquina {
	display: flex;
	.botonSidebar {
		display: none;
	}
}

@media (max-width: 1225px) {
	
	.logo {
		width: 90px;
	}
}



@media (max-width: 1090px) {

	.wrapEsquina{
		.botonSidebar{
			display: block;
		}
	}

	.link{
		color: white;
		font-size: 20px;
		&.active{
			color: white;
			&::after{
				display: none;
			}
		}
	}

	.wrapNavbarCenter {
		display: none;
	}

	.botones {
		display: none;
	}

	.botonSidebar {
		color: #160041;
		padding-right: 15px;
		cursor: pointer;
	}

	.wrapSidebar {
		display: block;
		.container{
			position: relative;
			img {
				position: absolute;
				top: 90px;
				right: -45px;
				z-index: 100;
				pointer-events: none;
	
				background-size: cover;
				background-repeat: no-repeat;
				mix-blend-mode: multiply;
				// opacity: 0.5;
				transform: scale( 2.3 ) rotate( 120deg );
			}
		}
	}


	$ancho: 100vw;
	$tope: 0vh;

	.sidebar {
		position: fixed;
		height: 100%;
		width: $ancho;
		top: $tope;
		z-index: 99;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 20px;
		background-color: #FF5757;
		overflow: hidden;
		transition: all 300ms ease-in;

		.wrapcloseBtn {
			display: flex;
			justify-content: flex-start;
			padding: 0;
			margin: 0;
			.closeBtn {
				width:     40px;
				font-size: 40px;
				cursor: pointer;
			}
		}
	}

	.open {
		left: 0px;
	}
	.close {
		left: -$ancho;
	}

	.listaSidebar {
		list-style: none;
		display: flex;
		padding: 0;
		margin: 0;
		margin-top: 8vh;
		flex-direction: column;
		gap: 24px;
		position: relative;
		z-index: 100;
	}

	.botonesAuth {
		height: 8vh;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-start;

		position: relative;
		z-index: 100;

		.btnEmpezar {

			width: 100%;
			max-width: 280px;
			min-width: 100px;

			padding: 10px 0px 11px 0px;
			
			color: #FF5757;
			font-feature-settings: 'clig' off, 'liga' off;
			font-family: "Inter";
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			border-radius: 19px;
			background: #FFF;
			border: none;
			outline: none;
		}
	}

	.authWrap {
		flex-direction: column;
	}
}
