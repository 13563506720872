.Thanks_Page {
	background-color: transparent;
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	.main-container {
		// height: 60vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		margin-inline: 6%;
		text-align: center;
		padding-bottom: 20px;
		.title {
			color: #ee4b4b;
			font-family: Krabuler;
			font-size: 9rem;
			font-weight: 400;
		}
		.words {
			color: #160041;
			font-size: 3rem;
			font-weight: 400;
		}
		.wrapImg {
			display: flex;
			justify-content: center;
			padding: 13px 0 13px 0;
			.cecy {
				width: 721px;
				height: auto;
			}
			.video-cecy{
				width: 100%;
				iframe{
					height: 265px;
					width: 475px;
					border-radius: 30px;
				}
			}
		}
		.wrapBtn {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 13px 0 0 0;
			.empezarBtn {
				color: white;
				padding: 15px 30px;
				display: flex;
				align-items: center;
				span {
					font-size: 24px;
				}
				margin: 0 auto;
				border: none;
				outline: none;
				border-radius: 76px;
				background: linear-gradient(180deg, #ff5757 0%, #eb00ff 100%);
				box-shadow: 0px 4.745px 35.588px 0px rgba(0, 0, 0, 0.2);
				.iconoBoton {
					margin-left: 10px;
					background-color: #fac71a;
					border-radius: 50%;
					.sizeIcon {
						width: 25px;
						font-size: 25px;
					}
				}
			}
		}
	}

	.imgBack1,
	.imgBack2 {
		position: absolute;
		mix-blend-mode: multiply;
	}

	.imgBack1 {
		z-index: -10;
		top: 200px;
		right: 70px;
		transform: scale(2.3) rotate(150deg);
	}

	.imgBack2 {
		z-index: -10;
		bottom: -5px;
		left: -90px;
		transform: scale(-1.8, 1.8) rotate(70deg);
	}
}

// movil
@media (max-width: 850px) {
	.Thanks_Page {
		.main-container {
			gap: 25px;
			.title {
				margin: 0 auto;
				text-align: center;
				width: 70%;
				font-size: 4rem;
			}
			.words {
				width: 85%;
				margin: 0 auto;
				text-align: justify;
				font-size: 2rem;
			}

			.wrapImg {
				order: 4;
				.cecy {
					width: 85vw;
				}
				.video-cecy{
					iframe{
						height: 195px;
						width: 340px;
					}
				}
			}
			.wrapBtn {
				order: 3;
				.empezarBtn {
					span {
						font-size: 18px;
					}
				}
			}
		}
		.imgBack1 {
			top: 10px;
			right: -250px;
			transform: scale(1.3) rotate(150deg);
		}

		.imgBack2 {
			bottom: -85px;
			left: -90px;
			transform: scale(-1.1, 1.1) rotate(70deg);
		}
	}
}
