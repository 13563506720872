.checkout-main-container {
	display: flex;
	padding: 40px;
	gap: 20px;
	font-family: Krabuler;
	h2 {
		font-size: 3rem;
	}
	h3 {
		font-size: 2rem;
		span {
			font-size: 2rem;
			color: #ff5757;
		}
	}
	.active {
		color: #fff;
		background: #ff5757 !important;
		transition: 0.3s ease all;
	}
	.left-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;
		.buttons-container {
			display: flex;
			gap: 20px;
			button {
				background: #e0e0e0;
				border-radius: 10px;
				border: none;
				width: 100%;
				padding-block: 15px;
				font-size: 2rem;
				transition: 0.3s ease all;
				&:hover {
					color: #fff;
					background: #ff5757;
					transition: 0.3s ease all;
				}
			}
		}
		.paymentMethods-container {
			display: flex;
			flex-direction: column;
			.paymentMethods-buttons-container {
				display: flex;
				gap: 20px;
				.paymentMethod-button {
					cursor: pointer;
					display: flex;
					flex-direction: column;
					align-items: center;
					background-color: #e0e0e0;
					border-radius: 10px;
					border: none;
					width: 100%;
					padding-block: 15px;
					font-size: 2rem;
					transition: 0.3s ease all;
					.cards {
						display: flex;
						gap: 10px;
						align-items: center;
						justify-content: center;
						height: 40px;
						overflow: hidden;
						img {
							width: 59px;
							height: 33px;
							object-fit: cover;
							border-radius: 2px;
						}
						.visa {
							background: #fff;
							border-radius: 2px;
						}
						.express {
							background: #006fcf;
							border-radius: 2px;
						}
					}
					&:hover {
						color: #fff;
						background: #ff5757;
						transition: 0.3s ease all;
					}
				}
			}
		}
		.card-container {
			display: flex;
			flex-direction: column;
			gap: 20px;
			.card-display {
				position: relative;
				display: flex;
				flex-direction: column;
				gap: 20px;
				padding: 20px 10px;
				width: 320px;
				min-width: 270px;
				max-width: 320px;
				height: 184px;
				background: linear-gradient(125deg, rgba(62, 62, 62, 1) 0%, rgba(0, 0, 0, 1) 100%);
				border-radius: 15px;
				input {
					color: #fff;
				}
				.red {
					width: 35px;
					height: 35px;
					background-color: rgba(229, 3, 26, 0.75);
					border-radius: 50px;
					position: absolute;
					right: 42px;
					top: 13px;
				}
				.yellow {
					height: 35px;
					width: 35px;
					background-color: rgba(245, 151, 19, 0.75);
					border-radius: 50px;
					position: absolute;
					right: 18px;
					top: 13px;
				}
				img {
					width: 35px;
					border-radius: 10px;
				}
				h4 {
					color: #fff;
				}
				input {
					background: none;
					border: none;
				}
				.date {
					position: absolute;
					bottom: 10px;
					right: 10px;
					width: 45px;
				}
			}
			.form {
				min-height: 240px;
				height: 100%;
				display: flex;
				flex-wrap: wrap;
				background: #ff5757;
				align-items: center;
				gap: 15px;
				padding: 3%;
				border-radius: 10px;
				width: 100%;
				select {
					cursor: pointer;
					color: #ff5757;
					border: none;
					border-radius: 50px;
					padding: 5px 1rem;
					outline: none;
				}
				.input-container {
					position: relative;
					width: 100%;
					background: #fff;
					display: flex;
					align-items: center;
					padding: 5px 1rem;
					gap: 10px;
					border-radius: 50px;
					svg {
						font-size: 1.6rem;
						color: #ff5757;
					}
					input {
						width: 100%;
						border: none;
						outline: none;
						color: #ff5757;
						&::placeholder {
							color: #ff5757;
						}
					}
				}
				.two-fields {
					width: 100%;
					display: flex;
					align-self: flex-end;
					gap: 2%;
					.field {
						width: 49%;
					}
				}
			}
		}
	}
	.description {
		flex-grow: 1;
		height: fit-content;
		min-width: 300px;
		max-width: 30%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-self: flex-end;
		gap: 15px;
		padding: 20px;
		padding-block-start: 30px;
		padding-block-end: 80px;
		background: #ff5757;
		border-radius: 10px;
		color: #fff;
		img {
			width: 120px;
			height: 120px;
			border-radius: 50%;
		}
		.price-container {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding-block: 15px;
			border-top: 2px solid #fff;
			border-bottom: 2px solid #fff;
		}
		p {
			text-align: justify;
		}
		button {
			border: 0px;
			border-radius: 100px;
			padding: 10px;
			width: 50%;
			color: #ff5757;
			margin-top: 20px;
			min-width: 280px;
		}
	}
	@media (max-width: 1000px) {
		flex-direction: column-reverse;
		.description {
			align-self: center;
			padding-block-end: 20px;
		}
		.left-content {
			.buttons-container,
			.paymentMethods-buttons-container {
				flex-direction: column;
			}
			.card-container {
				.card-display {
					max-width: 100%;
				}
				.form {
					.two-fields {
						flex-direction: column;
						gap: 15px;
						.field {
							width: 100%;
						}
					}
				}
			}
		}
	}
}
