.main-layout {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    overflow-x: hidden;

    > div {
        width: 100%;
        flex: 1;
    }
}
