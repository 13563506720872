.edit-user-main-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 20px;
  flex: 1;
  .user-top-info {
    display: flex;
    align-items: center;
    gap: 40px;
    img {
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
    h2 {
      font-size: 42px;
      color: #ff5757;
    }
  }
  .user-bottom-info {
    border-radius: 10px;
    padding: 2%;
    background: #ff5757;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-right: 60px;
    max-width: 500px;
    h2 {
      font-size: 32px;
      color: #fff;
    }
    input,
    select {
      padding: 5px 25px;
      font-size: 1.8rem;
      border: none;
      border-radius: 30px;
      background: #fff;
      color: #ff5757;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #ff5757;
      }
    }
    button {
      background: #fff;
      border: none;
      border-radius: 10px;
      padding: 10px 50px;
      color: #ff5757;
      font-size: 1.6rem;
      font-weight: 700;
      width: fit-content;
      margin: auto;
    }
  }
}
