.checkout-v2-container {
	display: flex;
	gap: 30px;
	justify-content: center;
	position: relative;
	width: 100%;
	margin-top: 60px;
	.phone-input{
		border: none;
		outline: 2px solid black;
		border-radius: 100px;
		padding-inline: 30px;
		padding-block: 10px;
		&:focus{
			outline: 2px solid #ff5757;
		}
		.PhoneInputInput{
			border: none;
			&:focus{
				outline: none;
			}
		}
	}
	.right-form {
		right: -50px;
		top: -162px;
		position: absolute;
		pointer-events: none;
	}
	.left-form {
		left: 0px;
		position: absolute;
		pointer-events: none;
	}
	.left-back {
		left: 0px;
		z-index: -1;
		position: absolute;
		pointer-events: none;
	}
	.announce{
		display: flex;
		position: absolute;
		padding-block: 15px;
		padding-bottom: 45px;
		padding-inline: 50px;
		color: #fff;
		background: linear-gradient(to bottom, #FF5757 0%, #EB00FF 100%);
		z-index: -1;
		border-radius: 50px 50px 0px 0px;
		top: -55px;
		right: 0px;
		font-size: 2.4rem;
	}
	.left-side {
		display: flex;
		flex-direction: column;
		gap: 5px;
		padding: 30px;
		border-radius: 40px;
		background-color: #fff;
		box-shadow: 0px 14px 12px 10px #f4eae1;
		width: 50%;
		position: relative;
		.header {
			display: flex;
			gap: 5px;
			align-items: center;
			justify-content: space-between;

			svg {
				margin-top: 5px;
				font-size: 22px;
				color: #ff5757;
				cursor: pointer;
			}
			h2 {
				font-size: 3rem;
				font-weight: 600;
				font-family: unset;
				color: #ff5757;
			}

			.currency-selector-container {
				display: flex;
				align-items: center;
				gap: 12px;
			
				label {
					font-size: 2.4rem;
					font-weight: 500;
				}
			
				select {
					border: none;
					font-weight: 600;
					font-size: 2rem;
					color: #ffffff;
					background-color: #2E51B1;
					border-radius: 16px;
					padding: 5px 15px;
					appearance: none;
					background-image: url("../../../public/assets/icons/arrow-chevron-down-white.svg");
					background-repeat: no-repeat;
					background-position: right 5px center;
					padding-right: 30px;
			
				}
			}
		}
		.data-container {
			padding-inline: 25px;
			display: flex;
			flex-direction: column;
			gap: 30px;
			height: 100%;
			.payment-methods {
				flex-wrap: wrap;
				justify-content: center;
			}
			.inputs {
				display: flex;
				flex-direction: column;
				gap: 28px;
				transition: 0.2s ease all;
				max-height: 300px;
				opacity: 1;
			}
			.not-card {
				padding-top: 0px;
				max-height: 0px;
				opacity: 0;
				pointer-events: none;
			}
			.row-input {
				display: flex;
				gap: 10px;
				width: 100%;
			}
			.pictures-row {
				display: flex;
				justify-content: center;
				gap: 20px;
				margin-top: 10px;
				align-items: center;
				.secure {
					width: 100px;
				}
				.stripe {
					width: 130px;
				}
			}
			.bottom-data {
				display: flex;
				flex-direction: column;
				margin-top: auto;
				align-items: center;
				gap: 5px;
				.row-data {
					display: flex;
					gap: 10px;
					width: 100%;
					justify-content: space-between;
					p {
						font-weight: 500;
						font-size: 1.8rem;
					}
				}
				.plan-selected {
					display: none;
					padding-block: 15px;
					border-top: 1px solid rgb(209, 209, 209);
					border-bottom: 1px solid rgb(209, 209, 209);
					margin-bottom: 5px;
					.type {
						color: #0052b4;
						font-weight: 500;
					}
				}
				.total {
					p {
						font-weight: 600;
						font-size: 2.4rem;
					}
				}
				button {
					margin-top: 5px;
					background-color: #ff5757;
					color: #fff;
					border: none;
					width: 100%;
					font-size: 2.4rem;
					border-radius: 100px;
					padding-block: 15px;
					transition: 0.2s ease all;
					&:hover {
						opacity: 0.67;
					}
				}
				.paypal-button {
					display: flex;
					justify-content: center;
					width: 80%;
				}
				button.paypal {
					background-color: unset;
					border-radius: 0px;
					padding-block: 0px;
					.paypal {
						margin: unset;
						width: 100%;
					}
				}
				button.oxxo {
					background-color: #e70c20;
					span {
						font-size: 2.4rem;
					}
				}
			}
		}
	}
	.right-side {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 30px;
		z-index: 10;
		.top-contain {
			display: flex;
			flex-direction: column;
			padding: 8px;
			border-radius: 50px;
			gap: 20px;
			box-shadow: -3px 14px 30px 16px #e5e5e5;
			background-color: white;
			width: 450px;
			.go-back {
				display: none;
			}
			.languages {
				display: flex;
				flex-direction: column;
				border-radius: 50px;
				.single-language {
					display: flex;
					justify-content: center;
					width: 100%;
					padding-block: 25px;
					position: relative;
					background-color: #f0f0f0;
					transition: 0.3s ease all;
					cursor: pointer;
					.img-contain {
						position: absolute;
						background-color: white;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						left: 20px;
						top: 50%;
						transform: translateY(-50%);
						img {
							width: 70px;
							height: 70px;
							background-color: #fff;
							border-radius: 50%;
							@media (max-width: 440px) {
								width: 55px;
								height: 55px;
							}
						}
					}
					.in-both {
						left: 57px;
						z-index: 2;
						@media (max-width: 440px) {
							left: 40px;
						}
					}
					h2 {
						color: #0052b4;
						font-size: 4rem;
					}
					&:first-child {
						border-radius: 50px 50px 0px 0px;
						border-bottom: 2px solid #dadada;
					}
					&:last-child {
						border-radius: 0px 0px 50px 50px;
						border-top: 2px solid #dadada;
					}
					&:hover {
						background-color: #d800287a;
					}
				}
				.selected-language {
					background-color: #d80027;
					h2 {
						color: #fff;
					}
					&:hover {
						background-color: #d80027;
						cursor: unset;
					}
				}
				.disabled-option {
					cursor: not-allowed;
					background-color: rgb(217, 217, 217);
					h2 {
						color: #8f8c8c;
					}
					&:hover {
						background-color: rgb(217, 217, 217);
						cursor: not-allowed;
					}
				}
			}
			.select-type {
				display: flex;
				gap: 10px;
				justify-content: center;
				align-items: center;
				p {
					font-size: 18px;
				}
				.input {
					width: 80px;
					background-color: #f0f0f0;
					box-shadow: 0px 0px 5px 1px #d6d4d4;
					height: 35px;
					border-radius: 100px;
					position: relative;
					cursor: pointer;
					.dot {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						border-radius: 50%;
						background-color: #0052b4;
						width: 29px;
						height: 29px;
						left: 6px;
						transition: 0.3s ease all;
					}
					&:hover {
						.dot {
							opacity: 0.8;
						}
					}
				}
				.anual {
					background-color: #0052b4;
					.dot {
						background-color: #fff;
						left: 80px - 35px;
					}
				}
			}
			.select-type-both {
				.input {
					cursor: not-allowed;
				}
			}
		}
	}
	.input-container {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
		.title {
			position: absolute;
			background-color: #fff;
			padding-inline: 3px;
			font-weight: 600;
			font-size: 12px;
			left: 20px;
			top: -10px;
		}
		.input {
			border: none;
			outline: 2px solid black;
			border-radius: 100px;
			padding-inline: 30px;
			padding-block: 10px;
			&:focus {
				outline: 2px solid #ff5757;
			}
		}
		.error {
			color: #ff0000;
			font-size: 12px;
			position: absolute;
			bottom: -20px;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	.payment-container {
		display: flex;
		gap: 10px;
		align-items: center;
		justify-content: space-between;
		padding-inline: 25px;
		padding-block: 12px;
		border: 1px solid black;
		border-radius: 100px;
		min-width: 280px;
		width: 100%;
		max-width: 320px;
		cursor: pointer;
		svg {
			color: black;
			font-size: 2.4rem;
		}
		p {
			color: black;
			font-weight: 500;
			font-size: 2rem;
		}
		.circle {
			display: flex;
			position: relative;
			min-width: 25px;
			width: 25px;
			height: 25px;
			border-radius: 50%;
			border: 1px solid black;
			.inner-circle {
				position: absolute;
				min-width: 17px;
				width: 17px;
				height: 17px;
				top: 2px;
				left: 2px;
				background-color: #fff;
				border-radius: 50%;
			}
		}
		&:hover {
			opacity: 0.6;
		}
	}
	.payment-selected {
		border: 1px solid #ff5757;
		svg {
			color: #ff5757;
		}
		p {
			color: #ff5757;
		}
		.circle {
			border: 2px solid #ff5757;
			.inner-circle {
				background-color: #ff5757;
			}
		}
		&:hover {
			opacity: 1;
		}
	}
	.bottom-contain {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding-inline: 50px;
		padding-block: 50px;
		border-radius: 50px;
		background-color: white;
		width: 450px;
		box-shadow: -10px 14px 20px #e5e5e5;
		z-index: 2;
		order: 3;
		.about {
			max-height: 150px;
			overflow: auto;
			&::-webkit-scrollbar {
				width: 10px;
			}
			&::-webkit-scrollbar-thumb {
				background: #160041;
				border-radius: 10px;
			}
		}
		.row-data {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.name {
				font-size: 3rem;
				font-weight: 600;
			}
			.back-ticks {
				width: 40px;
				height: 35px;
			}
			.rotation {
				rotate: 180deg;
			}
			.user {
				min-width: 45px;
				width: 45px;
				height: 45px;
				border-radius: 50%;
				object-fit: cover;
			}
		}
	}
	.resp-bottom {
		display: none;
	}
	@media (max-width: 1150px) {
		.input-container {
			.input {
				padding-right: 0px;
			}
		}
	}
	@media (max-width: 1023px) {
		flex-direction: column;
		align-items: center;
		margin-top: 0px;
		.payment-container {
			max-width: unset;
		}
		.left-form {
			top: 0px;
		}
		.right-form {
			right: -100px;
			top: unset;
			bottom: -70px;
		}
		.resp-bottom {
			display: flex;
		}
		.norm-bottom {
			display: none;
		}
		.size-resp {
			width: 70%;
		}
		.left-side {
			order: 2;
			box-shadow: 0px 4px 20px 10px #f4eae1;
			.announce{
				display: none;
			}
			.data-container {
				gap: 20px;
				.bottom-data {
					.plan-selected {
						display: flex;
					}
				}
				.inputs {
					padding-top: 20px;
					border-top: 1px solid rgb(209, 209, 209);
					.first {
						order: 2;
					}
					.second {
						order: 3;
					}
					.third {
						order: 1;
					}
				}
			}
		}
		.right-side {
			align-items: center;
			order: 1;
			.top-contain {
				width: 100%;
				padding: 20px;
				position: relative;
				.go-back {
					display: flex;
					gap: 5px;
					top: 15px;
					align-items: center;
					position: absolute;
					z-index: 10;
					opacity: 0;
					transition: 0.3s ease all;
					svg {
						margin-top: 5px;
						font-size: 22px;
						color: #ff5757;
						cursor: pointer;
					}
					p {
						font-size: 3rem;
						font-weight: 600;
						font-family: unset;
						color: #ff5757;
					}
				}
				.languages {
					background-color: #f0f0f0;
					border-radius: 30px;
					max-height: 98px;
					overflow: hidden;
					transition: 0.3s ease all;
					margin-top: 0px;
					cursor: pointer;
					.single-language {
						border-radius: 30px;
						&:first-child {
							border-radius: 30px;
							border-bottom: unset;
						}
						&:last-child {
							border-radius: 30px;
							border-top: unset;
						}
					}
					.selected-language {
						order: 1;
					}
					.order-unsel {
						order: 2;
					}
				}
				.select-type-both {
					display: none;
				}
			}
			.open-language {
				.languages {
					margin-top: 50px;
					max-height: 294px;
					overflow: unset;
				}
				.go-back {
					opacity: 1;
				}
			}
		}
		.input-container {
			.input {
				padding-right: 20px;
				padding-left: 20px;
			}
		}
	}
	@media (max-width: 750px) {
		.left-side {
			.data-container {
				padding-inline: 15px;
			}
		}
		.size-resp {
			width: 95%;
		}
		.bottom-contain {
			padding-block: 30px;
			padding-inline: 30px;
			border-radius: 30px;
			.row-data {
				.back-ticks {
					width: 30px;
					height: 22px;
				}
			}
		}
	}
	@media (max-width: 550px) {
		.row-input {
			.input-container {
				width: unset;
				.input {
					width: 100%;
				}
			}
		}
		.input-container {
			.title {
				// padding-inline: 3px;
				font-size: 10px;
				top: -9px;
				left: 12px;
			}
		}
	}
}
