.section-two {
    display: flex;
    justify-content: center;
    margin-inline: 6%;
    .main-container{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
        padding: 45px;
        background-color:#F6F6F680;
        border-radius: 40px;
        .left-side{
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 30%;
            min-width: 300px;
        }
        .lines{
            width: 6px;
            height: 150px;
        }
        .right-side{
            width: 30%;
            position: relative;
            min-width: 300px;
            .girl{
                position: absolute;
                width: 160%;
                border-radius: 400px 0px 600px 400px;
                top: -180px;
                left: 0px;
            }
        }
    }
    @media(max-width: 1023px){
        .main-container{
            flex-direction: column;
            padding: 0px;
            align-items: center;
            border-radius: 250px 400px 40px 40px;
            max-width: 600px;
            .left-side{
                width: unset;
                order: 2;
                text-align: center;
                align-items: center;
                padding-bottom: 30px;
            }
            .lines{
                display: none;
            }
            .right-side{
                order: 1;
                width: unset;
                .girl{
                    position: unset;
                    width: 100%;
                    border-radius: 400px 400px 0px 400px;
                    top: -180px;
                    left: 0px;
                }
            }
        }
    }
}