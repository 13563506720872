.confirm-modal-component {
    .modal-dialog {
        max-width: 700px;

        .modal-content {
            .modal-header {
                .modal-title {
                    font-size: 2.4rem;
                }
            }

            .modal-body {
                p {
                    font-size: 2rem;
                }
            }

            .modal-footer {
                justify-content: space-between;

                button {
                    border-radius: 10px;
                    padding: 5px 10px;
                    border: none;
                    font-size: 2rem;
                }

                button.cancel {
                    background-color: rgb(255, 71, 71);
                    color: #ffffff;
                }

                button.continue {
                    background-color: rgb(41, 243, 125);
                    color: #000000;
                }
            }
        }
    }
}