.powercoach-categories-container {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 60px;
	gap: 60px;
	font-family: Krabuler;
	@media (max-width: 675px) {
		padding-inline: 20px;
	}
	h2 {
		font-size: 3rem;
	}
	label {
		font-size: 2rem;
		color: #ff5757;
	}
	button {
		border-radius: 10px;
		padding: 10px;
		min-width: 300px;
		color: #fff;
		font-size: 1.8rem;
		border: none;
		background: #ff5757;
	}
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (max-width: 675px) {
			flex-direction: column;
		}
	}
	.page-one {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 40px;
		width: 100%;
		.categories-container {
			display: flex;
			flex-direction: column;
			gap: 15px;
			.categories-card {
				width: 100%;
				max-width: 700px;
				display: flex;
				padding: 20px;
				border-radius: 10px;
				box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
				align-items: center;
				h4 {
					font-size: 2rem;
				}
				a {
					cursor: pointer;
					margin-left: auto;
					color: #ff5757;
				}
			}
		}
	}
	.page-two {
		display: flex;
		flex-direction: column;
		gap: 20px;
		.return {
			display: flex;
			align-items: center;
			gap: 10px;
			position: absolute;
			left: 20px;
			top: 20px;
			cursor: pointer;
			p {
				margin: 0;
			}
			svg {
				width: 15px;
				height: 15px;
			}
		}
		.input-container {
			display: flex;
			flex-direction: column;
			gap: 10px;
			width: 100%;
			input,
			textarea {
				width: 100%;
				max-width: 700px;
				box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
				border: none;
				border-radius: 10px;
				padding: 5px;
				color: #ff5757 !important;
				&:focus {
					outline: none;
				}
			}
		}
	}
}
