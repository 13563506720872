.users-admin-main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px 10px;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: #ff5757;
      margin: 0;
      font-family: Krabuler;
    }
    .filter {
      font-size: 1.6rem;
      border: 1px solid #ff5757;
      border-radius: 30px;
      color: #ff5757 !important;
      padding: 5px 15px;
      margin-left: auto;
      &::placeholder {
        color: #ff5757;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px auto;
    p {
      margin: 0;
    }
    svg {
      width: 25px;
      height: 25px;
      color: #ff5757;
      cursor: pointer;
    }
    .page {
      padding: 10px 15px;
      background: #ff5757;
      color: #fff;
      border-radius: 10px;
    }
  }
  .table-header {
    display: grid;
    grid-template-columns: minmax(20px, 450px) minmax(20px, 350px) auto;
    border-bottom: 2px solid #ff5757;
    :last-child {
      text-align: center;
    }
    p {
      font-size: 18px;
      font-weight: 700;
      color: #ff5757;
      font-family: Krabuler;
    }
  }
  .user-table {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    :first-child {
      margin-top: 10px;
    }
    .user-container {
      display: grid;
      grid-template-columns: minmax(20px, 450px) minmax(20px, 350px) auto;
      background: #ff5757cf;
      padding: 10px;
      border-radius: 10px;
      p {
        color: #fff;
        margin: 0;
      }
      :last-child {
        text-align: center;
        cursor: pointer;
        color: #fff;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
