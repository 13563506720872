.payment-modal {
	.modal-dialog {
		max-width: 1200px;
		.modal-content {
			border-radius: 11px;
			border: none;
			.modal-body {
				border-radius: 10px;
				padding: 40px;
				display: flex;
				flex-direction: column;
				gap: 20px;
				background: #ff5757;
				.close {
					position: absolute;
					height: 30px;
					right: 30px;
					color: #fff;
					width: 30px;
					height: 30px;
					cursor: pointer;
				}
				input,
				p {
					font-size: 1.6rem;
				}
				.content {
					display: flex;
					gap: 2%;
					.form {
						width: 49%;
						display: flex;
						flex-direction: column;
						gap: 20px;
						.row-c {
							display: flex;
							gap: 20px;
							.custom {
								width: calc(50% - 10px) !important;
							}
							.input-container {
								width: 100%;
								position: relative;
								input {
									width: 100%;
									border: none;
									padding-left: 40px;
									padding-block: 8px;
									border-radius: 30px;
									&:focus {
										outline: none;
									}
								}
								svg {
									position: absolute;
									width: 15px;
									height: 15px;
									top: 50%;
									left: 15px;
									transform: translateY(-50%);
									color: #ff5757;
								}
							}
						}
					}
					.security-container {
						margin-top: 30px;
						width: 49%;
						color: #fff;
						h4 {
							font-size: 3rem;
						}
						p {
							color: #fff;
						}
					}
				}
				button {
					max-width: 300px;
					width: 100%;
					margin: auto;
					padding-block: 10px;
					font-size: 2rem;
					border: none;
					background: #fff;
					border-radius: 30px;
					color: #ff5757;
				}
			}
		}
	}
}
