.powercoach-forums-container {
	flex: 1;
	display: flex;
	justify-content: center;
	font-family: Krabuler;
	padding-block: 60px;
	position: relative;
	@media (max-width: 675px) {
		padding-inline: 20px;
		width: 100%;
	}
	h2 {
		font-size: 3rem;
	}
	button {
		border-radius: 10px;
		padding: 10px;
		min-width: 300px;
		color: #fff;
		font-size: 1.8rem;
		border: none;
		background: #ff5757;
	}
	.page-one {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 10px;
		.pages {
			position: absolute;
			top: 0;
			right: 0;
		}
		p {
			font-size: 2rem;
		}
		.new-card {
			margin-top: 20px;
			display: flex;
			justify-content: space-between;
		}
	}
	.page-two {
		display: flex;
		flex-direction: column;
		gap: 60px;
		p,
		label {
			font-size: 1.8rem;
		}
		.return {
			display: flex;
			align-items: center;
			gap: 10px;
			position: absolute;
			left: 20px;
			top: 20px;
			cursor: pointer;
			p {
				margin: 0;
			}
			svg {
				width: 15px;
				height: 15px;
			}
		}
		.forum-container {
			display: flex;
			flex-direction: column;
			gap: 10px;
			img {
				margin-inline: auto;
				width: 300px;
				height: 300px;
				margin: auto;
				padding: 20px;
				background-color: rgb(214, 214, 214);
				border-radius: 10px;
				margin-bottom: 20px;
			}
			.input-container {
				display: flex;
				flex-direction: column;
				gap: 10px;
				width: 100%;
				input,
				textarea {
					min-width: 300px;
					box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
					border: none;
					border-radius: 10px;
					padding: 5px;
					color: #ff5757 !important;
					&:focus {
						outline: none;
					}
				}
			}
		}
	}
}
