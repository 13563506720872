.community-card {
    max-width: 450px;
    max-height: 450px;
    width: 32%;
    min-width: 300px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1%;
    background-color: #ffffff;
    cursor: pointer;

    .image-container {
        width: 100px;
        height: 100%;
        display: flex;
        align-items: center;
        order: 1;

        img {
            width: 100%;
        }
    }

    h3 {
        font-size: 2rem;
        color: black;
    }
    .description-container {
        order: 2;
    }
    .social-network-name-container {
        order: 3;
    }
}

.community-card.new-order {
    .image-container {
        order: 1;
    }
    .description-container {
        order: 3;
    }
    .social-network-name-container {
        order: 2;
    }
}

.community-card:hover {
    background-color: var(--power-coach-bg-color);
}   