.home-main-container {
	// font-family: "Krabuler";
	display: flex;
	flex-direction: column;
	h1{
		font-family: "Krabuler";
		font-size: 5.5rem;
        text-align: center;
        color: #160041;
		width: 600px;
		span{
			font-size: 5.5rem;
		}
	}
	h2 {
		font-family: "Krabuler";
		font-size: 4rem;
		color: #FF5757;
		font-weight: 400;
	}
	.br-resp{
		display: none;
	}
	button{
        display: flex;
        gap: 20px;
        align-items: center;
        width: fit-content;
        padding-block: 15px;
        padding-inline: 40px;
        border-radius: 100px;
        border: none;
        color: #fff;
        font-weight: 600;
        p{
            font-size: 2.2rem;
        }
    }
	button.section-1{
		background: linear-gradient(to bottom, #FF5757 0%, #EB00FF 100%);
		svg{
            color: #FAC71A;
            font-size: 2.6rem;
            background-color: #fff;
            border-radius: 50%;
        }
	}
	button.section-2{
		background-color: #0052B4;
		gap: 5px;
		padding-inline: 20px;
		padding-block: 10px;
		p{
            font-size: 1.6rem;
			font-weight: 400;
        }
		svg{
			color: #fff;
			font-size: 1.6rem;
		}
	}
	@media(max-width: 1023px){
		.br-resp{
			display: unset;
		}
		.br-norm{
			display: none;
		}
		h1{
		    width: 415px;
			text-align: start;
			color: #FF5757;
			font-size: 4.8rem;
			span{
				color: black;
				font-size: 4.8rem;
			}
		}
		button{
			gap: 15px;
			padding-block: 12px;
			padding-inline: 30px;
			font-weight: 400;
		}
		button.section-1{
			svg{
				font-size: 2.2rem;
			}
		}
	}
	@media(max-width: 600px){
		h1{
		    width: 350px;
			font-size: 4rem;
			span{
				font-size: 4rem;
			}
		}
    }
}