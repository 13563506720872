.powercoach-main-container {
    * {
        margin: 0;
        padding: 0;
    }
    .personal-banner-container {
        position: relative;
        width: 100%;
        min-height: calc(100vw * 300 / 1440);
        overflow: hidden;

        img {
            position: absolute;
            width: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
        }
    }
    .power-coach-content-container {
        margin-top: -70px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        .power-coach-top-container {
            margin-bottom: 40px;

            .profile-pic-container {
                width: 240px;
                height: 240px;
                position: relative;
                overflow: hidden;
                border-radius: 100%;
                margin: auto;
                border: 4px solid white;
                box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;

                img {
                    position: absolute;
                    width: 100%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .name-followers-container {
                h2 {
                    font-size: 3.6rem;
                }
            }
        }

        .power-coach-profile-nav-container {
            position: relative;
            width: 100%;
            ul {
                display: flex;
                align-items: center;
                justify-content: center;
                list-style: none;

                a {
                    text-decoration: none;
                    padding: 5px 10px;
                    color: black;
                    font-size: 3rem;
                    border-bottom: 4px solid transparent;
                }

                a.active {
                    color: red;
                    border-bottom: 4px solid red;
                }
            }

            .bottom-border {
                width: 100%;
                height: 4px;
                background-color: #f2f2f4;
                position: absolute;
                bottom: 0;
                z-index: -1;
            }
        }
    }
}

@media (max-width: 750px) {
    .powercoach-main-container {
        .personal-banner-container {
            min-height: 200px;
            
            img {
                width: auto;
                height: 100%;
            }
        }
    }
}
