.questions-main-container {
	display: flex;
	flex-direction: column;
	gap: 40px;
	.questions-container {
		margin: auto;

		.content {
			display: flex;
			flex-direction: column;
			width: 100%;
			margin: auto;
			gap: 30px;
		}
	}
}

@media (max-width: 991px) {
}
