.premium-pc-main-container {
	padding: 1%;

	* {
		margin: 0;
		padding: 0;
	}

	.all-courses-card {
		width: 100%;
		padding: 20px 40px;
		box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
		min-width: 300px;
		border-radius: 20px;
		border: none;
		display: flex;
		flex-direction: column;
		gap: 20px;

		.main-title-container {
			text-align: center;

			h1 {
				font-size: 3.2rem;
			}
		}

		.courses-row {
			display: flex;
			flex-direction: column;
			gap: 15px;
			.title-subtitle-container {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				flex-wrap: wrap;
				h2,
				p {
					font-size: 2.8rem;
				}
			}

			.courses-cards-container {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 15px;
				overflow-x: scroll;
				padding-bottom: 10px;
			}
			.line {
				width: 100%;
				height: 10px;
				background: #ff5757;
				border-radius: 5px;
			}

			.courses-cards-container::-webkit-scrollbar {
				width: 10px;
				height: 10px;
			}

			.courses-cards-container::-webkit-scrollbar-track {
				background-color: transparent;
				border-radius: 10px;
			}

			.courses-cards-container::-webkit-scrollbar-thumb {
				background: linear-gradient(91.21deg, #ed1c24 0%, #7f3f98 99.67%);
				border-radius: 10px;
			}
		}

		.no-content-container {
			display: flex;
			justify-content: center;
			align-items: center;

			h2 {
				font-size: 4rem;
			}
		}
	}
}

@media (max-width: 600px) {
	.premium-pc-main-container {
		.all-courses-card {
			padding: 10px 20px;
		}
	}
}
