.modal-reset {
	.modal-dialog {
		.modal-content {
			border-radius: 30px;
			align-items: center;
			border: none;
			overflow: hidden;
			box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.234);
		}
	}
	.main-contain {
		display: flex;
		flex-direction: column;
		padding: 50px;
		width: 100%;
		text-align: center;
		position: relative;
		align-items: center;
		gap: 20px;
		h2 {
			font-size: 3rem;
			color: #ff5757;
		}
		.close {
			position: absolute;
			top: 20px;
			right: 20px;
			font-size: 2rem;
			cursor: pointer;
		}
		.sent {
			font-weight: 600;
			svg {
				color: rgb(61, 203, 61);
				margin-right: 5px;
				margin-bottom: 2px;
				font-size: 2rem;
			}
		}
		button {
			padding-block: 15px;
			padding-inline: 50px;
			border-radius: 100px;
			border: none;
			display: flex;
			gap: 20px;
			align-items: center;
			font-size: 2rem;
			color: #fff;
			font-weight: 600;
			background: linear-gradient(to bottom, #ff5757 0%, #eb00ff 100%);
		}
		.inputs {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			gap: 30px;
			.input-contain {
				display: flex;
				flex-direction: column;
				position: relative;
				width: 320px;
				.error {
					position: absolute;
					color: #ff0000;
					bottom: -20px;
					left: 50%;
					transform: translateX(-50%);
					font-size: 1.4rem;
					font-weight: 600;
					white-space: nowrap;
				}
				label {
					background-color: #f4f4f4;
					border-radius: 100px;
					position: absolute;
					color: #160041;
					left: 22px;
					top: -7px;
					padding-inline: 4px;
				}
				input {
					padding-block: 12px;
					padding-inline: 20px;
					border-radius: 100px;
					border: 1px solid #e4e4e4;
					background-color: #ffffffc4;
					color: #160041;
					font-weight: 600;
					&::placeholder {
						opacity: 0.6;
					}
					&:focus {
						outline: 2px solid #160041;
					}
				}
			}
		}
	}
}
