.powercoach-courses-container {
	flex: 1;
	display: flex;
	font-family: Krabuler;
	padding: 60px;
	position: relative;
	@media (max-width: 675px) {
		padding-inline: 20px;
	}
	h2 {
		font-size: 3rem;
	}
	label {
		font-size: 2rem;
		color: #ff5757;
	}
	button {
		margin: auto;
		border-radius: 10px;
		padding: 10px;
		min-width: 300px;
		color: #fff;
		font-size: 1.8rem;
		border: none;
		background: #ff5757;
	}
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (max-width: 675px) {
			flex-direction: column;
		}
	}
	.return {
		display: flex;
		align-items: center;
		gap: 10px;
		position: absolute;
		left: 20px;
		top: 20px;
		cursor: pointer;
		p {
			margin: 0;
		}
		svg {
			width: 15px;
			height: 15px;
		}
	}
	.page-one {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 40px;
		width: 100%;
		.course-cards {
			display: flex;
			flex-direction: column;
			gap: 20px;
			.course-card {
				display: flex;
				border-radius: 10px;
				box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
				align-items: center;
				gap: 10px;
				@media (max-width: 675px) {
					flex-direction: column;
					.edit {
						padding: 0 !important;
						margin: 0 !important;
						text-align: center;
					}
				}
				.edit {
					margin-left: auto;
					padding-right: 20px;
					display: flex;
					flex-direction: column;
					a {
						color: #ff5757;
						cursor: pointer;
					}
				}
				img {
					width: 200px;
					height: 100px;
					object-fit: cover;
					@media (max-width: 675px) {
						width: 100%;
						height: 200px;
					}
				}
				p {
					font-size: 1.8rem;
					color: #ff5757;
				}
			}
		}
	}
	.page-two {
		display: flex;
		flex-direction: column;
		gap: 60px;
		width: 100%;
		p,
		label {
			font-size: 1.8rem;
		}
		.course-container {
			display: flex;
			align-self: center;
			flex-direction: column;
			gap: 15px;
			max-width: 700px;
			width: 100%;
			.cover {
				margin-inline: auto;
				width: 300px;
				height: 300px;
				margin: auto;
				padding: 20px;
				background-color: rgb(214, 214, 214);
				border-radius: 10px;
				margin-bottom: 20px;
			}
			.banner {
				margin-inline: auto;
				max-width: 700px;
				width: 100%;
				height: 250px;
				margin: auto;
				padding: 20px;
				background-color: rgb(214, 214, 214);
				border-radius: 10px;
				margin-bottom: 20px;
				object-fit: cover;
			}
			input {
				border-radius: 10px;
				padding: 10px;
				box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
			}
			.input-container {
				display: flex;
				flex-direction: column;
				gap: 10px;
				width: 100%;
				input,
				textarea,
				select {
					min-width: 300px;
					box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
					border: none;
					border-radius: 10px;
					padding: 5px;
					color: #ff5757 !important;
					&:focus {
						outline: none;
					}
				}
			}
			.active-container {
				display: flex;
				gap: 15px;
				cursor: pointer;
				.switch {
					position: relative;
					width: 50px;
					height: 30px;
					border-radius: 30px;
					background-color: rgb(229, 229, 229);
					border-color: rgb(223, 223, 223);
					transition: 0.3s ease all;
					.pin {
						background: rgb(255, 255, 255);
						width: 30px;
						height: 30px;
						border-radius: 100%;
						box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
						position: absolute;
						top: 0;
						left: 0;
						transition: 0.3s ease-out all;
					}
					.pin-active {
						left: 20px;
						transition: 0.3s ease all;
					}
				}
				.switch-active {
					background-color: rgb(79, 192, 113);
					transition: 0.3s ease all;
				}
			}
		}
	}
}
