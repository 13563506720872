.powercoach-profile-container {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Krabuler;
	padding-block: 60px;
	position: relative;
	button {
		border-radius: 10px;
		padding: 10px;
		width: 50%;
		min-width: 300px;
		color: #fff;
		font-size: 1.8rem;
		border: none;
		background: #ff5757;
	}
	.first-step {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 40px;
		h4 {
			font-size: 2.8rem;
		}
	}
	.powercoach-info {
		display: flex;
		flex-direction: column;
		gap: 20px;
		max-width: 700px;
		width: 100%;
		h2 {
			font-size: 2.8rem;
		}
		p,
		a {
			margin: 0;
			font-size: 2rem;
		}
		.powercoach-box {
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 20px;
			border-radius: 10px;
			box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
			img {
				width: 120px;
				height: 120px;
				border-radius: 100px;
				border: 4px solid white;
				box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
			}
			a {
				margin-left: auto;
				cursor: pointer;
			}
		}
	}
	.second-step {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		color: #ff5757;
		@media (max-width: 675px) {
			width: 100%;
			padding-inline: 20px;
		}
		p,
		input,
		label,
		textarea {
			font-size: 1.8rem;
		}
		.return {
			display: flex;
			align-items: center;
			gap: 10px;
			position: absolute;
			left: 20px;
			top: 20px;
			cursor: pointer;
			p {
				margin: 0;
			}
			svg {
				width: 15px;
				height: 15px;
			}
		}
		.profile-img {
			width: 120px;
			height: 120px;
			border-radius: 100px;
			border: 4px solid white;
			box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
		}
		.banner-img {
			width: 500px;
			object-fit: cover;
			border-radius: 10px;
			height: 100px;
		}
		.input-container {
			display: flex;
			flex-direction: column;
			gap: 10px;
			width: 100%;
			input,
			textarea {
				min-width: 300px;
				box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
				border: none;
				border-radius: 10px;
				padding: 5px;
				color: #ff5757 !important;
				&:focus {
					outline: none;
				}
			}
		}
		.social-media {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			@media (max-width: 675px) {
				flex-direction: column;
				align-items: baseline;
			}
			.social {
				display: flex;
				gap: 10px;
				p {
					margin: 0;
				}
			}
			.link {
				min-width: 300px;
				box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
				border: none;
				border-radius: 10px;
				padding: 5px;
				color: #ff5757 !important;
				&:focus {
					outline: none;
				}
			}
		}
		.membership {
			display: flex;
			align-items: center;
			gap: 10px;
			width: 100%;
			p {
				margin: 0;
			}
		}
		.color {
			width: 100%;
			padding: 5px;
			border: none;
			height: 45px;
			border-radius: 10px;
			box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
		}
	}
}
