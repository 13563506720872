.powercoach-course-lessons-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	font-family: Krabuler;
	position: relative;
	button {
		margin: 0 !important;
	}
	.return {
		left: 0px !important;
		top: -40px !important;
	}
	.top {
		justify-content: baseline;
		margin-top: 20px;
		.buttons {
			margin-left: auto;
			display: flex;
			gap: 15px;
			.delete {
				background: #ff5757;
			}
			button {
				background: #91dbcb;
			}
		}
	}
	.lesson-card {
		margin-top: 20px;
		border-radius: 10px;
		box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
		display: flex;
		justify-content: space-between;
		padding: 20px;
		align-items: center;
		p,
		a {
			font-size: 2rem;
			color: #ff5757;
		}
		a {
			cursor: pointer;
		}
	}
	.lesson-container {
		display: flex;
		flex-direction: column;
		gap: 20px;
		.form {
			display: flex;
			flex-direction: column;
			gap: 15px;
			button {
				background: #91dbcb;
				width: fit-content;
			}
			.delete {
				background: #ff5757;
			}
			.input-container {
				display: flex;
				flex-direction: column;
				gap: 10px;
				width: 100%;
				max-width: 700px;
				input,
				textarea {
					min-width: 300px;
					box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
					border: none;
					border-radius: 10px;
					padding: 5px;
					color: #ff5757 !important;
					&:focus {
						outline: none;
					}
				}
			}
			.files-container {
				display: flex;
				flex-direction: column;
				gap: 10px;
				.file {
					display: flex;
					gap: 10px;
					align-items: center;
					i {
						font-size: 18px;
						cursor: pointer;
						color: red;
					}
				}
			}
		}
	}
}
