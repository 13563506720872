.free-pc-main-container {
    padding: 1%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .main-title-container {
        h1 {
            font-size: 3.2rem;
            text-align: center;
        }
    }   

    .free-courses-cards-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
    }
}