.section-five{
    display: flex;
    flex-direction: column;
    padding-block: 40px;
    .main-contain{
        display: flex;
        flex-direction: column;
        position: relative;
        padding-inline: 12%;
        h2{
            background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, rgba(255, 87, 87, 0.6) 0.01%, rgba(239, 123, 123, 0.6) 63.13%, rgba(246, 246, 246, 0.6) 100%);
            padding-inline: 25px;
            padding-block: 10px;
            border-radius: 30px 30px 0px 0px;
            color: #fff;
            font-weight: 400;
            z-index: 1;
            width: fit-content;
        }
        p{
            padding-block: 20px;
        }
        img{
            max-width: 800px;
            align-self: center;
        }
    }

}