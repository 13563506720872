.powercoach-sales-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	font-family: Krabuler;
	padding: 60px;
	position: relative;
	@media (max-width: 675px) {
		padding: 20px;
	}
	h2 {
		font-size: 4rem;
	}
	label {
		font-size: 2rem;
		color: #ff5757;
	}
	p {
		font-size: 2.4rem;
	}
	button {
		border-radius: 10px;
		padding: 10px;
		min-width: 150px;
		color: #fff;
		font-size: 1.8rem;
		border: none;
		background: #ff5757;
	}
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (max-width: 675px) {
			flex-direction: column;
			align-items: baseline;
		}
		p {
			color: #ff5757;
			margin: 0;
			font-family: Krabuler;
		}
		.filter {
			font-size: 1.6rem;
			border: 1px solid #ff5757;
			border-radius: 30px;
			color: #ff5757 !important;
			padding: 5px 15px;
			margin-left: auto;
			&::placeholder {
				color: #ff5757;
			}
			&:focus {
				outline: none;
			}
		}
	}
	.pagination {
		display: flex;
		align-items: center;
		gap: 10px;
		margin: 20px auto;
		p {
			margin: 0;
		}
		svg {
			width: 25px;
			height: 25px;
			color: #ff5757;
			cursor: pointer;
		}
		.page {
			padding: 10px 15px;
			background: #ff5757;
			color: #fff;
			border-radius: 10px;
		}
	}
	.sales-info-container {
		display: flex;
		flex-direction: column;
		gap: 10px;
		.filter {
			display: flex;
			gap: 10px;
			select {
				padding: 5px;
				border: 1px solid #ff5757;
				border-radius: 10px;
				outline: none;
				color: #ff5757;
			}
		}
		.users-container {
			display: flex;
			flex-direction: column;
			gap: 40px;
			.user-card {
				display: flex;
				justify-content: space-between;
				width: 100%;
				padding: 40px;
				border-radius: 10px;
				box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
				align-items: center;
				@media (max-width: 675px) {
					flex-direction: column;
					align-items: center;
				}
			}
		}
	}
}
