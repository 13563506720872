@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
	margin: 0;
	padding: 0;
	font-size: 62.5%;
	box-sizing: border-box;
}

html,
body,
#root {
	height: 100%;
	width: 100%;
}
@import "~bootstrap/scss/bootstrap.scss";

a {
	text-decoration: none !important;
}
h2,
h3,
h4 {
	font-family: Krabuler;
	font-weight: 900;
}

div {
	height: auto;
}

button:hover{
	opacity: 0.7;
}

div,
h1,
h2,
h3,
h4,
h5,
p,
a {
	font-size: 1.6rem;
	margin: 0;
}





@font-face {
	font-family: Krabuler;
	src: url("./styles/fonts/RF-Krabuler-Regular.ttf");
}
.paypal {
	margin: auto;
	iframe {
		#document {
			display: none;
		}
	}
	.buttons-container {
		display: none !important;
	}
	.paypal-button-row {
		background: none !important;
	}
}
