.section-one{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-top: 30px;
    position: relative;
    height: 65vh;
    .resp-description{
        display: none;
        text-align: center;
        background-color: #fff;
        border-radius: 10px;
        padding-inline: 30px;
        padding-block: 10px;
        box-shadow: 1px 1px 10px -6px black;
        font-size: 1.4rem;
        font-weight: 500;
    }
    .description-contain{
        display: flex;
        position: relative;
        .red-line{
            width: 32px;
            height: 8px;
            position: absolute;
            background-color: #FF5757;
            z-index: 2;
            top: 8px;
            left: -50px;
        }
        .yellow-arrow{
            position: absolute;
            top: -120px;
            left: -80px;
            width: 100px;
        }
        .red-arrow{
            position: absolute;
            top: 50px;
            right: -220px;
            width: 100px;
        }
        p{
            text-align: justify;
        }
    }
    .circle-form{
        position: absolute;
        z-index: -1;
        rotate: -75deg;
        top: -20px;
        width: 600px;
        mix-blend-mode: multiply;
    }
    .left-images{
        position: absolute;
        left: -200px;
        z-index: -2;
        top: 140px;
        width: 100%;
        .flower-form{
            width: 44%;
            mix-blend-mode: multiply;
        }
        .cecy{
            z-index: 1;
            width: 17%;
            position: absolute;
            left: 23%;
            top: -50px;
        }
        .yellow-arrow{
            display: none;
        }
        .red-arrow{
            display: none;
        }
    }
    .right-images{
        position: absolute;
        top: 20px;
        z-index: -2;
        width: 100%;
        .s-form{
            width: 44%;
            position: absolute;
            right: -180px;
            mix-blend-mode: multiply;
        }
        .kid{
            z-index: 1;
            width: 16%;
            position: absolute;
            right: 8%;
            top: 30px;
        }
    }
    @media(max-width: 1023px){
        margin-bottom: 50px;
        height: unset;
        gap: 30px;
        .circle-form{
            top: -50px;
            width: 550px;
        }
        .right-images{
            display: none;
        }
        .left-images{
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            left: unset;
            z-index: unset;
            top: unset;
            .flower-form{
                position: absolute;
                top: 40px;
                right: -160px;
                width: 80%;
                z-index: -1;
                min-width: 450px;
            }
            .cecy{
                z-index: unset;
                width: 40%;
                position: unset;
                left: unset;
                top: unset;
                min-width: 250px;
            }
            .yellow-arrow{
                display: unset;
                position: absolute;
                bottom: 0px;
                left: 25%;
                transform: translateX(-50%);
            }
            .red-arrow{
                display: unset;
                position: absolute;
                right: 30%;
                transform: translateX(50%);
            }
        }
        .resp-description{
            display: flex;
        }
        .description-contain{
            .yellow-arrow{
                display: none;
            }
            .red-arrow{
                display: none;
            }
        }
    }
    @media(max-width: 600px){
        .circle-form{
            top: 0px;
            width: 450px;
        }
        .description-contain{
            .red-line{
                display: none;
            }
        }
        .left-images{
            .yellow-arrow{
                left: 15%;
            }
            .red-arrow{
                right: 20%;
            }
        }
    }
}