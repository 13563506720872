.success-modal-component {
	.modal-dialog {
		max-width: 700px;
		color: #ff5757;
		.modal-content {
			align-items: center;
			.modal-header {
				border: none;
				.modal-title {
					font-size: 2.4rem;
				}
			}

			.modal-body {
				p {
					font-size: 2rem;
				}
			}

			.modal-footer {
				justify-content: space-between;
				border: none;
				button {
					border-radius: 10px;
					padding: 5px 10px;
					border: none;
					font-size: 2rem;
				}
				button.continue {
					background: #ff5757;
					color: #fff;
				}
			}
		}
	}
}
