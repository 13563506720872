.admin-powercoach-container {
	display: flex;
	@media (max-width: 675px) {
		flex-direction: column;
	}
	.open-menu {
		display: none;
		@media (max-width: 675px) {
			display: flex;
			align-items: center;
			gap: 10px;
			background: #ff5757;
			width: fit-content;
			padding: 10px;
			color: #fff;
			border-radius: 15px;
			margin-left: 10px;
			margin-top: 10px;
			svg {
				font-size: 3rem;
			}
		}
	}
	.active {
		left: 0 !important;
		transition: 0.3s ease all !important;
	}
	.side-bar {
		width: fit-content;
		background: #ff5757;
		padding: 20px;
		@media (max-width: 675px) {
			z-index: 2;
			position: absolute;
			left: -100%;
			width: 100%;
			transition: 0.3s ease all;
		}
		svg {
			display: none;
			@media (max-width: 675px) {
				display: flex;
				font-size: 3rem;
				color: #fff;
				margin-left: auto;
			}
		}
		ul {
			padding: 0;
			.active {
				color: #fac71a;
				border-bottom: 2px solid #fac71a;
			}
			li {
				font-family: Krabuler;
				color: #fff;
				list-style: none;
				font-size: 21px;
				&:hover {
					color: #fac71a;
				}
			}
		}
	}
}
