.dropdown-card-main-container {
	width: 84.7916667vw;
	margin: auto;
	border-radius: 12px;
	border: 1px solid #dcdcdc;
	background: #fafafa;
	box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);

	&.close {
		.text-container {
			display: none;
			height: 0;
			p {
				display: none;
			}
		}
		.header-container {
			.icon-container {
				svg {
					transform: rotate(0deg);
				}
			}
		}
	}

	&.open {
		.text-container {
			display: block;

			height: 15vh;
			p {
				display: block;
			}
		}
		.header-container {
			.icon-container {
				svg {
					transform: rotate(-90deg);
				}
			}
		}
	}

	&:hover.open {
		background: #fafafa;
	}

	&:hover {
		background: rgba(black, 0.05);
	}

	.header-container {
		cursor: pointer;
		padding: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		transition: all 300ms ease-in-out;
		.header {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-direction: row;
			gap: 15px;

			svg {
				width: 1.5vw;
				height: auto;
			}

			h2 {
				font-family: "Inter";
				font-size: 2rem;
				margin: 0;
			}
		}

		.icon-container {
			svg {
				width: 1.5vw;
				height: auto;
				transition: all 300ms ease-in;
			}
		}
	}

	.text-container {
		max-height: 500px;
		height: 15vh;
		overflow: hidden;
		padding: 35px;
		transition: all 1s ease-in-out;
		p,
		b {
			font-family: "Inter";
			font-size: 24px;
		}
	}
}

// .dropdown-card-main-container.close {
//     height: 0;
// }
