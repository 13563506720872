.modal-component{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    .modal-dialog{
        .modal-content{
            border-radius: 30px;
            align-items: center;
            border: none;
            overflow: hidden;
        }
    }
    .figure{
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;
        pointer-events: none;
    }
    form{
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-block: 30px;
        align-items: center;
        border-radius: 30px;
        position: relative;
        width: 80%;
        .active{
            cursor: pointer;
            &:hover{
                opacity: .67;
            }
        }
        .close{
            position: absolute;
            font-size: 2rem;
            top: 20px;
            right: -30px;
            cursor: pointer;
            &:hover{
                opacity: .67;
            }
        }
        h2{
            font-size: 3rem;
            color: #FF5757;
        }
        p{
            font-size: 1.8rem;
            font-weight: 300;
            color: #160041;
            u{
                font-size: 1.8rem;
                font-weight: 300;
                color: #160041;
            }
        }
        .error{
            position: absolute;
            color: #ff0000;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 1.4rem;
            font-weight: 600;
            white-space: nowrap;
        }
        .no-account{
            font-weight: 500;
            u{
                font-weight: 500;
            }
        }
        button{
            padding-block: 15px;
            padding-inline: 50px;
            border-radius: 100px;
            border: none;
            display: flex;
            gap: 20px;
            align-items: center;
            font-size: 2rem;
        }
        button.start{
            color: #fff;
            font-weight: 600;
            background: linear-gradient(to bottom, #FF5757 0%, #EB00FF 100%);
        }
        button.google{
            border: 4px solid transparent;
            background: linear-gradient(white, white) padding-box, linear-gradient( 180deg, #FF5757 0%, #EB00FF 100% ) border-box;
            p{
                background: -webkit-linear-gradient( 270deg, #FF5757 0%, #EB00FF 100%  );
                background-clip: text;
                color: transparent;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
                font-size: 2rem;
            }
        }
        .spin-contain{
            margin-block: 10px;
        }
        hr{
            border: 1px solid #0b0b0b;
            width: 90%;
            opacity: .25;
        }
        .input-contain{
            display: flex;
            flex-direction: column;
            position: relative;
            width: 320px;
            label{
                background-color: #F4F4F4;
                border-radius: 100px;
                position: absolute;
                color: #160041;
                left: 22px;
                top: -7px;
                padding-inline: 4px;
            }
            input{
                padding-block: 12px;
                padding-inline: 20px;
                border-radius: 100px;
                border: 1px solid #E4E4E4;
                background-color: #ffffffc4;
                color: #160041;
                font-weight: 600;
                &::placeholder{
                    opacity: .6;
                }
                &:focus{
                    outline: 2px solid #160041;
                }
            }
        }
        .row-contain{
            display: flex;
            align-items: center;
            gap: 10px;
            position: relative;
            .checkbox{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 22px;
                height: 22px;
                border-radius: 2px;
                border: 2px solid #160041;
                position: relative;
                cursor: pointer;
                .check{
                    opacity: 0;
                    transition: .2s ease all;
                }
                .check-select{
                    opacity: 1;
                }
            }
        }
    }
    @media(max-width: 500px){
        form{
            width: 90%;
            gap: 25px;
            .close{
                right: -10px;
            }
            p{
                font-size: 1.6rem;
                u{
                    font-size: 1.6rem;
                }
            }
            button{
                padding-inline: 30px;
                font-size: 1.6rem;
                gap: 10px
            }
            button.google{
                p{
                    font-size: 1.6rem; 
                }
            }
            .error{
                font-size: 1.2rem;
            }
        }
    }
}