.forums-pc-main-container {
    padding: 1%;

    .card {
        width: 100%;
        padding: 20px 40px;
        box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
        min-width: 300px;
        border-radius: 20px;
        border: none;
    }

    .all-forums-card {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .title-container {
            h1 {
                font-size: 3.2rem;
                text-align: center;
            }
        }

        .forums-cards-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }
    }
}
@media (max-width: 980px) {
    .forums-pc-main-container {
        .all-forums-card {
            .forums-cards-container {
                flex-wrap: wrap;
            }
        }
    }
}
