.forum-component-main-container {
    padding: 5%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .go-back-btn-container {
        button {
            border: none;
            background: transparent;
            font-weight: bolder;
            font-size: 2rem;
        }
    }

    .title-container {
        h1 {
            text-align: center;
            font-size: 3.6rem;
        }
    }

    .text-container {
        p {
            font-size: 1.8rem;
        }
    }

    .comments-cards-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .forum-comment-card {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .image-container {
                width: 50px;
                aspect-ratio: 1/1;
                border-radius: 100px;
                background-color: #cacaca;
                position: relative;
                overflow: hidden;

                img {
                    position: absolute;
                    width: 100%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .comment-info-container {
                border-radius: 10px;
                background-color: var(--power-coach-bg-color);
                flex: 1;
                padding: 10px;
            }
        }
    }
}
