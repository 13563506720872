footer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 41.5px;
	
	background-color: white;// #eeeeee;
	
	width: 100%;
	height: 272px;
	min-height: 80px;
	padding: 77px 0px 42px 0px;

	p {
		color: #777;
		text-align: center;
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%; /* 18px */
	}
	// a {
	// 	font-size: 1.4rem;
	// 	margin: 0;
	// 	color: #3a3a3a;
	// 	font-weight: 400;
	// 	line-height: 2.8rem;
	// 	cursor: pointer;
	// }
}
