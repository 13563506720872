.planes{
	position: absolute;
	bottom: 4px;
	right: 4px;
	font-size: 11px;
}
.currencySelectorContainer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 12px;
	width: 100%;
	margin: 30px auto;
	label {
		font-size: 3.2rem;
		font-weight: 600;
	}

	select {
		border: none;
		font-weight: 600;
		font-size: 2rem;
		color: #160041;
		border-radius: 16px;
		padding: 5px 15px;
		appearance: none;
		background-image: url("../../../public/assets/icons/arrow-chevron-down.svg");
		background-repeat: no-repeat;
		background-position: right 5px center;
		padding-right: 30px;

	}

	@media(min-width: 1290px) {
		min-width: 1200px;
	}
}

.academy-main-container {
	background-color: white;
	position: relative;
	overflow: hidden;
	mix-blend-mode: multiply;
}

.figura_fondo1 {
	position: absolute;
	top: 0vh;
	left: 0vw;
	z-index: -1;
}

.figura_fondo2 {
	position: absolute;
	top: 15vh;
	right: -50px;
	z-index: -1;
	transform: scale(0.85);
}

.figura_fondo3 {
	position: absolute;
	top: 30vh;
	left: -450px;
	z-index: -1;
	mix-blend-mode: multiply;
	transform: scale(2) rotate(12deg);
}

.figura_fondo4 {
	position: absolute;
	bottom: -150px;
	left: 0;
	z-index: -105;
	transform: scale(1.25);
	// mix-blend-mode: screen;
	opacity: 0.05;
}

.figura_fondo5 {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: -1;
	mix-blend-mode: multiply;
}

.figura_fondo6 {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: -1;
	mix-blend-mode: multiply;
}

.botonFlotante {
	position: absolute;
	top: 568px;
	left: 45.7vw;
	padding: 0;
	margin: 0;
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background: linear-gradient(180deg, #ff5757 0%, #eb00ff 100%);
		border-radius: 50%;
		width: 40px;
		min-width: 40px;
		height: 40px;
	}
	&:hover {
		opacity: 0.7;
	}
}

.lineas {
	position: absolute;
	width: 0.65%;

	top: 10%;
	left: 49vw;

	padding: 0;
	margin: 0;
}

.title {
	color: #ff5757;
	text-align: center;
	font-family: Krabuler;
	font-size: 80px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 2px;
}

.wrapLanguages {
	width: 78vw;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin-bottom: 100px;
	img {
		width: 20vw;
		margin: 5vh auto;
	}
}

.language {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.wrapSelectLanguage {
	position: relative;
	display: flex;
	flex-direction: column;

	width: 18vw;
	min-width: 330px;
	padding: 1rem 4rem;

	border-radius: 72px;
	background: #ececec;
}

.selectLanguage1 {
	display: flex;
	justify-content: flex-end;

	& > span:nth-child(2) {
		font-size: 3.5rem;
		color: #0052b4;
		font-family: Krabuler;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
	}

	button {
		position: absolute;
		top: -20px;
		left: -35px;

		display: flex;
		align-items: center;
		justify-content: space-between;
		text-align: center;
		padding: 1.7vh 2vw;

		border-radius: 73.6px;
		border: none;
		outline: none;
		box-shadow: 0px 4.6px 34.5px 0px rgba(0, 0, 0, 0.2);

		background: #0052b4;

		span {
			color: #fff;
			font-family: "Inter";
			font-size: 2rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
}

.selectLanguage2,
.selectLanguageEnglish2 {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding: 0;
	margin: 0;

	& > * {
		color: #292d32;
		font-family: "Inter";
		font-size: 1.3rem;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
}

.selectLanguageEnglish1 {
	display: flex;
	justify-content: flex-start;

	& > span:nth-child(1) {
		font-size: 4rem;
		font-family: Krabuler;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
		color: #d80027;
	}

	button {
		position: absolute;
		top: -20px;
		right: -35px;

		display: flex;
		align-items: center;
		justify-content: space-between;
		text-align: center;
		padding: 1.7vh 2vw;

		border-radius: 73.6px;
		border: none;
		outline: none;
		box-shadow: 0px 4.6px 34.5px 0px rgba(0, 0, 0, 0.2);

		background: #d80027;

		span {
			color: #fff;
			font-family: "Inter";
			font-size: 2rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
}

.selectLanguage1_boton_icon {
	background-color: #d80027;
	width: fit-content;
	border-radius: 50px;

	margin-left: 10px;
}

.selectLanguageEnglish2_boton_icon {
	background-color: #fff;
	width: fit-content;
	border-radius: 50px;

	margin-left: 10px;
}

.tableSection {
	// margin: 10.7407407vh auto 15.7407407vh auto;
	height: 100%;
	width: 100%;

	.title {
		color: #160041;
		text-align: center;
		font-family: "Inter";
		font-size: 5.2rem;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		letter-spacing: 2px;
	}

	.wrapTable {
		// width: 65%;
		margin: 0 auto;
		th {
			min-width: 300px;
		}
		.header {
			margin-top: 3vh;
			font-size: 8rem;
			tr {
				background-color: rgba(246, 246, 246, 0.7);
			}

			.cabecera {
				text-align: center !important;
				padding-top: 2vh !important;

				&:last-child {
					border-top-right-radius: 36px;
					padding-right: 2rem;
				}
				&:first-child {
					border-top-left-radius: 36px;
				}
			}
		}

		.table {
			width: 100%;
			font-size: 10rem;
			border-collapse: separate;
			border-spacing: 0;

			tbody {
				background-color: rgba(246, 246, 246, 0.7);

				tr:last-child {
					td:first-child {
						border-bottom-left-radius: 36px;
					}
					td:last-child {
						border-bottom-right-radius: 36px;
					}
				}
			}

			.desc {
				color: #160041;
				font-family: "Inter";
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 2px;
				padding-left: 36px;
				padding-top: 24px;
			}

			$font-family: "Inter";
			$font-size: 19px;
			$font-style: normal;
			$font-weight: 700;
			$line-height: normal;
			$letter-spacing: 2px;

			@mixin common-styles {
				text-align: center;
				margin: auto;
				font-family: $font-family;
				font-size: $font-size;
				font-style: $font-style;
				font-weight: $font-weight;
				line-height: $line-height;
				letter-spacing: $letter-spacing;
			}

			.yellowColor {
				color: #fac71a;
				@include common-styles;
			}

			.redColor {
				color: #d80027;
				@include common-styles;
			}

			.blueColor {
				color: #160041;
				background-color: #d9e2fb;
				@include common-styles;
			}

			.greyColor {
				color: grey;
				text-decoration: line-through;
				text-decoration-color: red;
				background-color: #d9e2fb;
				@include common-styles;
			}

			.costos {
				padding: 3vh 0;
			}

			.btnBase {
				color: white;
				padding: 8px 24px;
				display: flex;
				justify-content: center;
				font-size: 20px;
				align-items: center;
				border-radius: 64px;
				margin: 0 auto;
				border: none;
				outline: none;
			}

			.btnYellow {
				@extend .btnBase;
				background-color: #fac71a;
			}

			.btnRed {
				@extend .btnBase;
				background-color: #d80027;
			}

			.btnBlue {
				@extend .btnBase;
				background-color: #160041;
			}
		}
	}

	.cards {
		display: none;
	}
}

@media (min-width: 1px) {
	.tableSection {
		margin: 15vh auto 1.7407407vh auto;

		.wrapTable {
			width: 90%;
		}
	}
}

@media (min-width: 852px) {
	.botonFlotante {
		top: 540px;
		left: 47.5vw;
	}
}

@media (( min-width: 853px ) and ( max-width: 905px )) {
	.title {
		font-size: 65px;
	}
	.lineas {
		left: 48.5vw;
	}
}

@media (min-width: 1300px) {
	.botonFlotante {
		top: 585px;
		left: 47.5vw;
	}

	.tableSection {
		margin: 15vh auto 5.7407407vh auto;

		.wrapTable {
			width: 80%;
		}
	}
}

@media (min-width: 1500px) {
	.botonFlotante {
		top: 655px;
		left: 47.5vw;
	}
	.lineas {
		width: 0.6%;
		left: 49vw;
	}
}

@media (min-width: 1600px) {
	.tableSection {
		margin: 15vh auto 1.7407407vh auto;

		.wrapTable {
			width: 70%;
		}
	}
}

@media (min-width: 1800px) {
	.botonFlotante {
		top: 750px;
		left: 48.5vw;
	}
	.lineas {
		width: 0.6%;
		left: 49.5vw;
	}
}

// movil
@media (( min-width: 1px )  and ( max-width: 851px )) {
	// query general

	// responsivo utils ------------------------------------
	.cardPrime {
		max-width: 580px;
		display: flex;
		padding: 32px 24px;
		flex-direction: column;
		align-items: flex-start;
		gap: 28px;

		font-family: "Inter";

		border-radius: 32px;

		margin: 0 auto;
		height: auto;
		width: 100%;
	}
	.costCardPrime {
		font-size: 32px;
		font-weight: 700;
		letter-spacing: 2px;
		position: relative;
		.pagoCardPrime {
			position: absolute;
			left: 4px;
			bottom: -13px;

			font-size: 16px;
			font-weight: 400;
			letter-spacing: 2px;
		}
	}
	.idiomasPrime {
		font-size: 20px;
		font-weight: 600;
		letter-spacing: 2px;
		margin-bottom: 18px;
		margin-top: 4px;
	}

	.listCard {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 18px;
		list-style: none;
		padding: 0;
		li {
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 2px;
		}
	}
	.titleCard {
		color: #160041;
		font-size: 24px;
		font-weight: 600;
		letter-spacing: 2px;
	}
	.descCard {
		color: #160041;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 2px;
	}
	.wrapBoton {
		margin: auto;
		width: 100%;
		button {
			border: none;
			outline: none;
			width: 100%;
			font-size: 20px;
			padding: 10px 0;
			border-radius: 64px;
		}
	}

	// ---------------------------------------------------------------

	.botonFlotante {
		display: none;
	}
	.lineas {
		display: none;
	}

	.title {
		font-size: 40px;
	}

	.wrapLanguages {
		flex-direction: column;
		.language {
			img {
				width: 50vw;
			}
		}
	}
	.tableSection {
		.title {
			font-size: 32px;
		}
		.table {
			display: none;
		}
		.cards {
			display: flex;
			flex-direction: column;
			gap: 10vh;
			margin-top: 5vh;
			margin-bottom: 10vh;
			padding: 20px;

			.card1 {
				@extend .cardPrime;
				background-color: rgba(#d9e2fb, 0.64);

				.costCard1 {
					@extend .costCardPrime;
					color: #123289;

					.pagoCard1 {
						@extend .pagoCardPrime;
						color: #123289;
					}
				}

				.idiomas1 {
					@extend .idiomasPrime;
					color: #1943b2;
				}
				li {
					color: #160041;
				}
				button {
					color: white;
					background-color: #123289;
				}
			}
			.card2,
			.card3 {
				background: rgba(#f6f6f6, 0.64);
			}

			.card2 {
				$colorLetra: #af0d2a;
				@extend .cardPrime;

				.costCard2 {
					@extend .costCardPrime;
					color: $colorLetra;

					.pagoCard2 {
						@extend .pagoCardPrime;
						color: $colorLetra;
					}
				}

				.idiomas2 {
					@extend .idiomasPrime;
					color: $colorLetra;
				}
				li {
					color: #160041;
				}
				button {
					color: white;
					background-color: $colorLetra;
				}
			}

			.card3 {
				$colorLetra3: #ffa800;
				@extend .cardPrime;

				.costCard3 {
					@extend .costCardPrime;
					color: $colorLetra3;

					.pagoCard3 {
						@extend .pagoCardPrime;
						color: $colorLetra3;
					}
				}

				.idiomas3 {
					@extend .idiomasPrime;
					color: $colorLetra3;
				}
				li {
					color: #160041;
				}
				button {
					color: white;
					background-color: $colorLetra3;
				}
			}
		}
	}
}
@media (( min-width: 1px ) and ( max-width: 451px )) {
	.wrapLanguages {
		.language {
			img {
				width: 40vw;
			}

			.wrapSelectLanguage {
				min-width: 280px;
				padding: 1rem 3rem;
				.selectLanguage1 {
					button {
						top: -10px;
						left: -10px;
					}
				}
				.selectLanguage2 {
					& > * {
						font-size: 1.1rem;
					}
				}

				.selectLanguageEnglish1 {
					button {
						top: -10px;
						right: -4px;
					}
				}
				.selectLanguageEnglish2 {
					& > * {
						font-size: 1.1rem;
					}
				}
			}
		}
	}
}
