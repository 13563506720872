.course-main-container {
	.banner {
		width: 100%;
		aspect-ratio: 11/4;
		position: relative;
		display: flex;
		align-items: center;
		background: linear-gradient(180deg, #00000000, #0000005f);

		img {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: -1;
		}

		.title-container {
			width: 100%;
			padding-left: 5%;

			h1 {
				color: #ffffff;
				font-size: 3rem;
				font-family: Krabuler;
			}
		}
	}

	.content-container {
		padding: 40px;
		display: flex;
		flex-direction: column;
		gap: 20px;

		.course-nav {
			ul {
				list-style: none;
				padding: 0px;
				margin: 0px;
				display: flex;

				li {
					padding: 5px 10px 5px 20px;
					border-bottom: 4px solid #dfdfdf;
					cursor: pointer;
					font-size: 2rem;
				}
				li.active {
					border-color: var(--power-coach-color);
				}
			}
		}

		.course-content-container {
			display: flex;
			flex-direction: column;
			gap: 20px;

			.modules-container {
				border-radius: 5px;
				border: 2px solid var(--power-coach-color);
				display: flex;
				width: fit-content;
				@media (max-width: 1023px) {
					width: 100%;
					overflow: auto;
				}
				.module {
					padding: 5px 10px;
					color: var(--power-coach-color);
					cursor: pointer;
					font-weight: bold;
				}

				.module.active {
					background-color: var(--power-coach-color);
					color: #ffffff;
				}
			}
		}
	}
}
