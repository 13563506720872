.modal-gift{
   display: flex;
   flex-direction: column;
   align-items: center;
   border-radius: 30px;
   .modal-dialog{
       .modal-content{
           border-radius: 30px;
           align-items: center;
           border: none;
           overflow: hidden;
       }
   }
   .main-contain{
      display: flex;
      flex-direction: column;
      padding: 50px;
      text-align: center;
      position: relative;
      align-items: center;
      .close{
         position: absolute;
         font-size: 2rem;
         top: 20px;
         right: -20px;
         cursor: pointer;
         &:hover{
             opacity: .67;
         }
     }
      p{
         color: #160041;
      }
      .title{
         font-size: 3rem;
         font-weight: 500;
      }
      .price-contain{
         display: flex;
         justify-content: center;
         position: relative;
         p{
            font-size: 2.2rem;
            font-weight: 500;
         }
         hr{
            position: absolute;
            width: 170px;
            top: 6px;
            left: 50%;
            transform: translateX(-50%);
            border: 1px solid #FF5757;
            opacity: 1;
         }
      }
      .sale{
         color: #FF5757;
         font-size: 3.4rem;
         font-weight: 600;
         margin-top: 10px;
      }
      .bottom-text{
         font-size: 2rem;
         opacity: .35;
      }
      button{
         margin-top: 50px;
         width: fit-content;
         padding-block: 15px;
         padding-inline: 50px;
         border-radius: 100px;
         border: none;
         font-size: 1.6rem;
         color: #fff;
         font-weight: 600;
         background: linear-gradient(to bottom, #FF5757 0%, #EB00FF 100%);
     }
   }
   @media(max-width: 470px){
      .main-contain{
         padding: 30px;
         .close{
            right: 20px;
         }
         button{
            padding-inline: 0px;
            width: 100%;
         }
      }
   }
}