.default-pay-modal {
    .modal-dialog {
        max-width: 500px;
        .modal-content {
            .modal-body {
                * {
                    margin: 0px;
                    padding: 0px;
                }

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 20px;
                padding: 20px;

                h2 {
                    font-size: 2.4rem;
                }

                .payment-info-card {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
                    padding: 40px 20px;
                    border-radius: 10px;

                    img {
                        width: 60px;
                    }

                    a {
                        font-weight: 400;
                        font-size: 50px;
                        background: -webkit-linear-gradient(#ed1c24, #7f3f98);
                        background-clip: border-box;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-family: krabuler;
                    }

                    p {
                        font-size: 1.8rem;
                    }
                }

                button.pay-btn {
                    border: none;
                    background-color: var(--power-coach-color);
                    border-radius: 10px;
                    padding: 5px 10px;
                    font-size: 2.4rem;
                    font-family: krabuler;
                }

                button:disabled.pay-btn {
                    background-color: #cecece;
                }
            }
        }
    }
}
