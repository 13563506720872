.admin-powercoach-main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px 10px;
  .table-header {
    display: grid;
    grid-template-columns:
      minmax(20px, 350px) minmax(20px, 200px) minmax(20px, 200px)
      minmax(20px, 150px) minmax(20px, 150px) auto;
    grid-gap: 10px;
    border-bottom: 2px solid #ff5757;
    :last-child {
      text-align: center;
    }
    p {
      font-size: 18px;
      font-weight: 700;
      color: #ff5757;
      font-family: Krabuler;
    }
  }
  .user-table {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    :first-child {
      margin-top: 10px;
    }
    .user-container {
      display: grid;
      grid-template-columns:
        minmax(20px, 350px) minmax(20px, 200px) minmax(20px, 200px)
        minmax(20px, 150px) minmax(20px, 150px) auto;
      grid-gap: 10px;
      background: #ff5757cf;
      padding: 10px;
      border-radius: 10px;
      input {
        background: none;
        color: #fff;
        border: 2px solid;
        border-radius: 5px;
        font-size: 1.6rem;
      }
      p {
        color: #fff;
        margin: 0;
      }
      :last-child {
        text-align: center;
        cursor: pointer;
        color: #fff;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
