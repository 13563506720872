.powercoach-subscriptions-container {
	flex: 1;
	display: flex;
	font-family: Krabuler;
	padding: 60px;
	position: relative;
	@media (max-width: 675px) {
		padding-inline: 20px;
	}
	h2 {
		font-size: 3rem;
	}
	label {
		font-size: 2rem;
		color: #ff5757;
	}
	button {
		margin: auto;
		border-radius: 10px;
		padding: 10px;
		min-width: 300px;
		color: #fff;
		font-size: 1.8rem;
		border: none;
		background: #ff5757;
	}
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (max-width: 675px) {
			flex-direction: column;
			text-align: center;
		}
	}
	.return {
		display: flex;
		align-items: center;
		gap: 10px;
		position: absolute;
		left: 20px;
		top: 20px;
		cursor: pointer;
		p {
			margin: 0;
		}
		svg {
			width: 15px;
			height: 15px;
		}
	}
	.page-one {
		width: 100%;
		.subscription-card {
			margin-top: 20px;
			border-radius: 10px;
			box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
			display: flex;
			padding: 20px;
			align-items: center;
			gap: 20px;
			p,
			a {
				font-size: 2rem;
				color: #ff5757;
			}
			:last-child {
				margin-left: auto;
				cursor: pointer;
				color: #ff5757;
			}
			@media (max-width: 675px) {
				flex-direction: column;
				:last-child {
					margin: 0;
				}
			}
		}
	}
	.page-two {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;
		.input-container {
			display: flex;
			flex-direction: column;
			gap: 10px;
			width: 70%;
			input,
			textarea,
			select {
				min-width: 300px;
				box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
				border: none;
				border-radius: 10px;
				padding: 5px;
				color: #ff5757 !important;
				&:focus {
					outline: none;
				}
			}
		}
	}
}
