.lecture-main-container {
	padding: 40px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	* {
		margin: 0;
		padding: 0;
		font-size: 1.6rem;
	}

	.header {
		h1 {
			font-size: 3.2rem;
			font-family: Krabuler;
		}
	}

	.wrap-container {
		display: flex;
		gap: 40px;
		flex-wrap: wrap;
		width: 100%;

		.lecture-content-container {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 40px;
			border-radius: 10px;

			.video-section {
				width: 100%;

				.video-container {
					aspect-ratio: 16/9;
					width: 100%;

					> div {
						width: 100%;
						height: 100%;

						iframe {
							width: 100%;
							height: 100%;
						}
					}
				}
			}

			.lecture-info-container {
				display: flex;
				flex-direction: column;
				gap: 20px;
				ul {
					list-style: none;
					padding: 0px;
					margin: 0px;
					display: flex;

					li {
						padding: 5px 10px 5px 20px;
						border-bottom: 4px solid #dfdfdf;
						cursor: pointer;
						font-size: 2rem;
					}
					li.active {
						border-color: var(--power-coach-color);
					}
				}

				.info-outlet {
					a {
						color: black;
					}
					.lecture-comments-container {
						display: flex;
						flex-direction: column;
						gap: 20px;
						.comment-card-container {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							gap: 10px;

							.image-container {
								width: 50px;
								aspect-ratio: 1/1;
								border-radius: 100px;
								background-color: #cacaca;
								position: relative;
								overflow: hidden;

								img {
									position: absolute;
									width: 100%;
									left: 50%;
									top: 50%;
									transform: translate(-50%, -50%);
								}
							}

							.comment-info-container {
								border-radius: 10px;
								background-color: var(--power-coach-bg-color);
								flex: 1;
								padding: 10px;
							}
						}
					}
				}
			}
		}

		.other-lectures-section {
			min-width: 400px;
			display: flex;
			flex-direction: column;
			gap: 10px;

			h2 {
				font-size: 2.4rem;
			}

			.other-lectures-cards-container {
				display: flex;
				flex-direction: column;
				gap: 20px;

				.other-lecture-card {
					aspect-ratio: 295/166;
					background-color: #cecece;
					animation: skeleton-loading 1s linear infinite alternate;
					border-radius: 10px;
					position: relative;
					overflow: hidden;

					img {
						position: absolute;
						width: 100%;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
					}

					.label-lecture-name {
						height: 30%;
						position: absolute;
						left: 0;
						bottom: 0;
						background-color: #0000006d;
						width: 100%;
						padding-top: 10px;
						padding-left: 20px;

						h3 {
							font-size: 2rem;
							color: white;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 950px) {
	.lecture-main-container {
		padding: 40px 20px;
		.wrap-container {
			.lecture-content-container {
				.lecture-info-container {
					ul {
						li {
							padding: 5px;
						}
					}
				}
			}

			.other-lectures-section {
				width: 100%;
				min-width: auto;
				margin: auto;
			}
		}
	}
}

@media (max-width: 400px) {
	.lecture-main-container {
		.wrap-container {
			.lecture-content-container {
				.lecture-info-container {
					ul {
						li {
							font-size: 1.4rem;
						}
					}
				}
			}
		}
	}
}

@keyframes skeleton-loading {
	0% {
		background-color: #cecece;
	}
	100% {
		background-color: #f0f0f0;
	}
}
