.no-subscription-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    * {
        margin: 0;
    }

    h1 {
        font-size: 3.2rem;
    }

    h2 {
        font-size: 2.8rem;
        color: #ff5758;
    }
}