.profile-main-container {
	display: flex;
	flex-direction: column;
	padding: 30px 60px;
	gap: 100px;
	@media (max-width: 700px) {
		padding: 30px 20px;
	}
	p {
		margin: 0;
		font-weight: 700;
		font-size: 2rem;
		font-family: "Crete Round", Sans-serif;
		color: #ff5757;
	}
	.top {
		display: flex;
		align-items: center;
		gap: 20px;
		@media (max-width: 700px) {
			flex-wrap: wrap;
			justify-content: center;
			h2 {
				font-size: 3.6rem !important;
			}
			button {
				margin: 0 !important;
			}
		}
		img {
			width: 120px;
			height: 120px;
			border-radius: 50%;
		}
		h2 {
			font-size: 48px;
		}
		button {
			text-transform: capitalize;
			margin-left: auto;
			border-radius: 10px;
			border: 2px solid #ff5757;
			color: #ff5757;
			font-weight: 700;
			background: none;
			font-size: 18px;
			padding: 5px 12px;
		}
	}
	.tabs {
		display: flex;
		gap: 20px;
		a {
			font-family: Krabuler;
			font-size: 2rem;
			cursor: pointer;
			color: #ff5757;
			&:hover {
				color: #fac71a;
			}
		}
		.active {
			color: #fac71a;
			border-bottom: 2px solid #fac71a;
		}
	}
	.user-info {
		box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
		border-radius: 10px;
		padding: 2rem;
		display: flex;
		flex-direction: column;
		gap: 10px;
		h4 {
			font-size: 3rem;
		}
		.input-container {
			position: relative;
			display: flex;
			align-items: center;
			max-width: 450px;
			width: 100%;
			input {
				font-size: 1.6rem;
				width: 100%;
				border-radius: 100px;
				border: 1.5px solid #ff5757;
				padding: 5px 10px;
				color: #ff5757;
				font-weight: 600;
				&:focus {
					outline: none;
				}
			}
			svg {
				position: absolute;
				right: 10px;
				width: 20px;
				height: 20px;
			}
		}
		button {
			max-width: 450px;
			width: 100%;
			font-size: 1.6rem;
			font-weight: 600;
			border-radius: 10px;
			border: 1.5px solid #ff5757;
			background: none;
			padding: 5px 10px;
			color: #ff5757;
			transition: 0.3s ease all;
			&:hover {
				color: #fff;
				background: #ff5757;
				transition: 0.3s ease all;
			}
		}
	}
	.subscription-container {
		display: flex;
		flex-direction: column;
		gap: 40px;
		.payment {
			padding: 30px 50px;
			width: 100%;
			height: auto;
			background: #ff5757;
			border-radius: 10px;
			h4 {
				color: #fff;
				font-size: 3rem;
			}
			.payment-container {
				margin-top: 20px;
				background: #fff;
				padding: 17px 15px;
				display: flex;
				align-items: center;
				border-radius: 10px;
				color: #ff5757;
				gap: 20px;
				svg {
					width: 25px;
					height: 25px;
					color: #ff5757;
				}
			}
			.content {
				margin-top: 20px;
				background: #fff;
				padding: 17px 15px;
				border-radius: 10px;
				text-align: center;
				border: none;
				width: 100%;
			}
		}
	}
}
