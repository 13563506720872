.subscriptions-card-component {
	background-color: var(--power-coach-bg-color);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 20px;
	height: 100%;

	* {
		margin: 0px;
		padding: 0px;
	}

	.title-container {
		h2 {
			font-size: 2.8rem;
		}
	}

	.type-selector {
		display: flex;
		justify-content: space-between;
		gap: 20px;

		button {
			flex: 1;
			border-radius: 10px;
			padding: 5px;
			font-size: 2rem;
			background-color: transparent;
			border: 3px solid #ffffff;
			font-weight: bolder;
			font-family: krabuler;
		}

		button.active {
			border: 3px solid var(--power-coach-color);
			background-color: var(--power-coach-bg-color);
		}
	}

	.option-selector-container {
		margin-top: auto;

		.donation-options {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 20px;

			.donation-circle {
				background-color: #ffffff;
				border-radius: 100%;
				width: 4rem;
				aspect-ratio: 1/1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				p {
					text-align: center;
					font-size: 1.5rem;
					font-weight: bolder;
				}
			}
		}
		.subscription-options {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 20px;
			label {
				font-size: 2.4rem;
			}

			.form-switch {
				input {
					width: 7rem;
					height: 3.5rem;
				}

				input:checked {
					background-color: var(--power-coach-color);
					border-color: var(--power-coach-color);
				}
			}
		}
	}

	.total-container {
		display: flex;
		flex-direction: column;
		gap: 20px;
		label {
			font-size: 1.8rem;
		}

		.input-container {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			gap: 10px;

			p {
				font-size: 2rem;
				font-weight: 500;
			}
			input {
				flex: 1;
				background-color: transparent;
				border: none;
				gap: 10px;
				font-size: 2rem;
				font-weight: 500;
				color: black;
			}
		}

		.input-container.donation {
			border: 3px solid #ffffff;
			padding: 5px 10px;
			border-radius: 10px;
			align-items: center;
		}

		.subscription-info-container {
			display: flex;
			flex-direction: column;
			gap: 10px;
			align-items: center;
			justify-content: center;
			margin: auto;

			h2 {
				font-size: 2.8rem;
			}

			h3 {
				font-size: 6.4rem;
				color: var(--power-coach-color);
			}
		}
	}

	.buttons-container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		gap: 10px;
		.paypal {
			display: flex;
			:first-child {
				margin: auto;
			}
		}
		button {
			flex: 1;
			padding: 10px;
			border-radius: 10px;
			background-color: var(--power-coach-color);
			border: none;
			font-family: krabuler;
			font-size: 2.4rem;
		}
	}
}
