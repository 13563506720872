.powercoach-users-container {
	flex: 1;
	display: flex;
	font-family: Krabuler;
	padding: 60px;
	position: relative;
	@media (max-width: 675px) {
		padding-inline: 20px;
	}
	h2 {
		font-size: 3rem;
	}
	label {
		font-size: 2rem;
		color: #ff5757;
	}
	button {
		margin: auto;
		border-radius: 10px;
		padding: 10px;
		min-width: 300px;
		color: #fff;
		font-size: 1.8rem;
		border: none;
		background: #ff5757;
	}
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.filter {
			font-size: 1.6rem;
			border: 1px solid #ff5757;
			border-radius: 30px;
			color: #ff5757 !important;
			padding: 5px 15px;
			margin-left: auto;
			&::placeholder {
				color: #ff5757;
			}
			&:focus {
				outline: none;
			}
		}
	}
	.return {
		display: flex;
		align-items: center;
		gap: 10px;
		position: absolute;
		left: 20px;
		top: 20px;
		cursor: pointer;
		p {
			margin: 0;
		}
		svg {
			width: 15px;
			height: 15px;
		}
	}
	.page-one {
		width: 100%;
		.pagination {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			margin: 20px auto;
			p {
				margin: 0;
			}
			svg {
				width: 25px;
				height: 25px;
				color: #ff5757;
				cursor: pointer;
			}
			.page {
				padding: 10px 15px;
				background: #ff5757;
				color: #fff;
				border-radius: 10px;
			}
		}
		.users-container {
			display: flex;
			flex-direction: column;
			gap: 40px;
			.user-card {
				display: flex;
				justify-content: space-between;
				width: 100%;
				padding: 40px;
				border-radius: 10px;
				box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
				align-items: center;
				a {
					cursor: pointer;
				}
				@media (max-width: 675px) {
					flex-direction: column;
					align-items: center;
				}
			}
		}
	}
	.page-two {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 70%;
		@media (max-width: 675px) {
			width: auto;
		}
	}
}
