.course-card-component {
    margin: 0;
    padding: 0;
    border: none;
    position: relative;
    width: 100%;
    max-width: 387px;
    height: 212px;
    border-radius: 10px;
    overflow: hidden;
    min-width: 387px;

    * {
        margin: 0;
    }



    img {
        width: 100%;
        height: 100%;
    }

    .info-container {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #00000085;
        width: 100%;
        padding: 16px;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 1.8rem;

        h3 {
            font-size: 1.8rem;
        }
    }

    .course-type-container {
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 10px 0px 0px 0px;
        color: #ffffff;
        width: 125px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #30c42f;
        p {
            text-align: center;
            font-weight: bolder;
        }
    }
}


@media (max-width: 600px) {
    .course-card-component {
        min-width: 300px;
    }
}