.powercoach-socialNetworks-container {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 60px;
	gap: 40px;
	font-family: Krabuler;
	@media (max-width: 675px) {
		padding-inline: 20px;
	}
	h2 {
		font-size: 3rem;
	}
	label {
		font-size: 2rem;
		color: #ff5757;
	}
	button {
		border-radius: 10px;
		padding: 10px;
		min-width: 300px;
		color: #fff;
		font-size: 1.8rem;
		border: none;
		background: #ff5757;
	}
	select {
		width: 100%;
		max-width: 700px;
		box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
		border: 1px solid #ff5757;
		border-radius: 10px;
		padding: 5px;
		color: #ff5757 !important;
		&:focus {
			outline: none;
		}
	}
	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (max-width: 675px) {
			flex-direction: column;
		}
		.filter {
			font-size: 1.6rem;
			border: 1px solid #ff5757;
			border-radius: 30px;
			color: #ff5757 !important;
			padding: 5px 15px;
			margin-left: auto;
			&::placeholder {
				color: #ff5757;
			}
			&:focus {
				outline: none;
			}
		}
	}
	.pagination {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		margin: 0px auto;
		p {
			margin: 0;
		}
		svg {
			width: 25px;
			height: 25px;
			color: #ff5757;
			cursor: pointer;
		}
		.page {
			padding: 10px 15px;
			background: #ff5757;
			color: #fff;
			border-radius: 10px;
		}
	}
	.social-container {
		display: flex;
		flex-direction: column;
		gap: 15px;
		.user-card {
			justify-content: space-between;
			width: 100%;
			display: flex;
			padding: 20px;
			border-radius: 10px;
			box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
			align-items: center;
			color: #ff5757;
			@media (max-width: 675px) {
				flex-direction: column;
			}
		}
	}
}
