.general-pc-main-container {
	padding: 1%;
	display: flex;
	flex-direction: column;
	gap: 20px;

	.card {
		width: 100%;
		padding: 20px 40px;
		box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
		min-width: 300px;
		border-radius: 20px;
		border: none;
	}

	.top-container {
		display: flex;
		width: 100%;
		gap: 15px;
		flex-wrap: wrap;
		> div {
			flex: 1;
		}

		.about-benefits-container {
			display: flex;
			flex-direction: column;
			gap: 15px;

			> div {
				flex: 1;
			}

			.about-card {
				display: flex;
				flex-direction: column;
				gap: 20px;

				h2 {
					font-size: 2.8rem;
				}

				.social-icons-container {
					img {
						width: 2.4rem;
						margin-right: 10px;
					}
				}
			}

			.benefits-card {
				display: flex;
				flex-direction: column;
				gap: 20px;

				h2 {
					font-size: 2.8rem;
				}

				.become-member-icons-container {
					display: flex;
					justify-content: space-evenly;

					.become-member-icons {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						img {
							width: 55px;
							aspect-ratio: 1/1;
						}

						p {
							text-align: center;
						}
					}
				}
			}
		}
	}

	.secondary-container {
		display: flex;
		flex-direction: column;
		gap: 20px;

		section h2 {
			font-size: 3.2rem;
		}

		.community-container,
		.premium-container {
			display: flex;
			flex-direction: column;
			gap: 20px;

			.community-cards-container {
				display: flex;
				justify-content: flex-start;
				gap: 20px;
			}
		}

		.donors-container {
			overflow: hidden;

			.donors-cards-container {
				display: flex;
				gap: 20px;
				animation: primary 30s linear infinite;
				width: fit-content;

				.donors-card {
					display: flex;
					align-items: center;
					margin-bottom: 10px;
					font-size: 1.5rem;
					justify-content: center;
					border-radius: 10px;
					padding: 20px 40px;
					box-shadow: rgb(149 157 165 / 30%) 0px 8px 8px;
					width: -webkit-max-content;
					width: max-content;
					flex-direction: row;

					.image-container {
						width: 60px;
						height: 60px;
						min-width: 60px;
						position: relative;
						overflow: hidden;
						border-radius: 100px;
						margin-right: 10px;
						border: 4px solid white;
						box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;

						img {
							position: absolute;
							width: 100%;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}
			}
		}
	}
}

@keyframes primary {
	0% {
		transform: translate(0, 0);
	}
	5% {
		transform: translate(0, 0);
	}
	95% {
		transform: translate(-100%, 0);
	}
	100% {
		transform: translate(-100%, 0);
	}
}

@media (max-width: 980px) {
	.general-pc-main-container .secondary-container {
		.premium-container,
		.community-container {
			.community-cards-container {
				flex-wrap: wrap;
			}
		}
	}
}

@media (max-width: 720px) {
	.general-pc-main-container {
		.card {
			padding: 10px 20px;
		}

		.secondary-container {
			.community-container {
				.community-cards-container {
					justify-content: center;
				}
			}
		}
	}
}
